import React from 'react';
import Typography from '@mui/material/Typography';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import { useLocation } from 'react-router-dom';
import { useState } from 'react';
import { useEffect } from 'react';
import { getMatrimonio } from '../../services/matrimoniosService';
import PaginationTable from '../../components/tables/PaginationTable';
import { convertDate, convertToInputDateFormat } from '../../utils/utils';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import TextField from '@mui/material/TextField';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BasicSelect from '../../components/basicSelect/BasicSelect';
import { getCursos, descargarExcelCursos, eliminarCursoMatrimonio, actualizarCursoMatrimonio, registrarCursoMatrimonio } from '../../services/cursoService';
import toast from 'react-hot-toast';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Tooltip from '@mui/material/Tooltip';
import { getToday } from '../../utils/utils';
import EditIcon from '@mui/icons-material/Edit';
import DeleteIcon from '@mui/icons-material/Delete';

function AsignarCursoMatrimonio() {
    const theme = useTheme();
    const location = useLocation();
    const [rows, setRows] = useState([]);
    const { idMatrimonio } = location.state || {};
    const [matrimonio, setMatrimonio] = useState(null);
    const [filteredRows, setFilteredRows] = useState([]); // Estado para las filas filtradas
    const [search, setSearch] = useState(''); // Estado para el valor de búsqueda
    const [open, setOpen] = React.useState(false);
    const [openDeleteCurso, setOpenDeleteCurso] = React.useState(false);
    const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
    const [maxWidth] = React.useState('md');
    const [selectedNivel, setSelectedNivel] = React.useState('');
    const [selectedCurso, setSelectedCurso] = React.useState('');
    const [idEstCurMat, setIdEstCurMat] = React.useState(null);
    const [cursos, setCursos] = React.useState([]);
    const [lugarCurso, setlugarCurso] = React.useState('');
    const [fechaDesde, setFechaDesde] = React.useState(getToday());
    const [fechaHasta, setFechaHasta] = React.useState(getToday());
    const [disertante, setDisertante] = React.useState('');
    const [isEditMode, setIsEditMode] = useState(false);

    const columns = [
        { id: 'id_est_cur_mat', label: 'ID', minWidth: 5 },
        { id: 'nivel', label: 'Nivel', minWidth: 50 },
        { id: 'nombre_curso', label: 'Curso', minWidth: 100 },
        { id: 'fecha_desde', label: 'Fecha desde', minWidth: 50 },
        { id: 'fecha_hasta', label: 'Fecha hasta', minWidth: 50 },
        { id: 'lugar', label: 'Lugar', minWidth: 100 },
        { id: 'disertante', label: 'Disertante', minWidth: 100 }
    ];

    const nivelCiclo = [
        { id: 1, ciclo: 'CBF' },
        { id: 2, ciclo: 'Dirigencial' },
        { id: 3, ciclo: 'Profundización' },
    ];

    useEffect(() => {

        async function fetchData() {
            if (!idMatrimonio) return;
            await fetchMatrimonioData(idMatrimonio);
            const cursosData = await fetchCursos();
            setCursos(cursosData);
        }

        fetchData();

    }, [idMatrimonio]);

    useEffect(() => {
        // Función para filtrar las filas basada en la búsqueda
        const filterRows = () => {
            const lowercasedSearch = search.toLowerCase();
            const filtered = rows.filter(row =>
            (row.nombre_curso?.toLowerCase().includes(lowercasedSearch) ||
                row.nivel?.toLowerCase().includes(lowercasedSearch) ||
                row.fecha_desde?.includes(lowercasedSearch) ||
                row.fecha_hasta?.includes(lowercasedSearch))
            );
            setFilteredRows(filtered);
        };

        if (search === '') {
            setFilteredRows(rows); // Reset a la lista total cuando la búsqueda está vacía
        } else {
            filterRows();
        }

    }, [search, rows]);


    const fetchMatrimonioData = async (id) => {
        try {
            const data = await getMatrimonio(id);
            setMatrimonio(data);

            const mappedRows = data.estudio_cursado_matrimonios.map(curso => ({
                id_matrimonio: curso.id_matrimonio,
                id_est_cur_mat: curso.id_est_cur_mat,
                id_curso: curso.id_curso,
                nombre_curso: curso.cursos.nombre_curso,
                nivel: curso.cursos.nivel_ciclo.ciclo,
                fecha_desde: convertDate(curso.fecha_desde),
                fecha_hasta: convertDate(curso.fecha_hasta),
                lugar: curso.lugar,
                disertante: curso.disertante
            }));
            setRows(mappedRows);
            setFilteredRows(mappedRows);
        } catch (error) {
            console.error('Error al obtener los datos del matrimonio:', error);
        }
    };

    const handleConfirmDelete = async () => {
        try {
            await eliminarCursoMatrimonio(idEstCurMat);
            setOpenDeleteCurso(false);
            await fetchMatrimonioData(idMatrimonio);
            toast.success('Curso eliminado correctamente.');
        } catch (error) {
            console.error('Error al eliminar el curso:', error);
            toast.error('Error al eliminar el curso.');
        }
    };

    const handleClose = () => {
        setOpen(false);
    };

    const handleCloseDelete = () => {
        setOpenDeleteCurso(false);
    };

    const handleOpenDeleteCurso = (idEstCurMat) => {
        setIdEstCurMat(idEstCurMat);
        setOpenDeleteCurso(true);
    };

    const handleNivelChange = (event) => {
        setSelectedNivel(event.target.value);
    };

    const handleCursoChange = (event) => {
        setSelectedCurso(event.target.value);
    };

    const fetchCursos = async () => {
        try {
            const data = await getCursos();
            return data;
        } catch (error) {
            console.error('Error al obtener los datos de los cursos:', error);
        }
    }

    const handleDownloadExcel = async () => {
        try {
            const response = await descargarExcelCursos(idMatrimonio);
            const url = window.URL.createObjectURL(new Blob([response]));
            const link = document.createElement('a');
            document.body.appendChild(link);
            link.href = url;
            link.download = 'cursos-matrimonio.xlsx';
            link.click();
            link.remove();
        } catch (error) {
            console.error('Error al descargar el archivo:', error);
            toast.error('Error al descargar el archivo.');
        }
    };

    // Función para abrir el modal en Modo Creación
    const handleClickOpen = () => {
        setIsEditMode(false);
        setIdEstCurMat(null);
        setSelectedNivel('');
        setSelectedCurso('');
        setlugarCurso('');
        setFechaDesde(getToday());
        setFechaHasta(getToday());
        setDisertante('');
        setOpen(true);
    };

    // Función para abrir el modal en Modo Edición
    const handleClickEdit = (curso) => {
        setIsEditMode(true);
        setIdEstCurMat(curso.id_est_cur_mat);
    
        // Buscar el nivel y asignarlo correctamente
        const nivelEncontrado = nivelCiclo.find(nivel => nivel.ciclo === curso.nivel);
        setSelectedNivel(nivelEncontrado ? nivelEncontrado.id : '');
    
        // Asegurar que `curso.id_curso` existe en el objeto
        setSelectedCurso(curso.id_curso || '');
    
        // Recuperar valores correctamente
        setlugarCurso(curso.lugar || '');
        setDisertante(curso.disertante || '');
    
        // Manejar fechas asegurando el formato `YYYY-MM-DD`
        setFechaDesde(curso.fecha_desde ? convertToInputDateFormat(curso.fecha_desde) : getToday());
        setFechaHasta(curso.fecha_hasta ? convertToInputDateFormat(curso.fecha_hasta) : getToday());
    
        setOpen(true);
    };

    // Guardar cambios (Editar o Crear)
    const handleConfirmar = async () => {
        try {
            if (!selectedNivel || !selectedCurso || !lugarCurso || !fechaDesde || !fechaHasta || !disertante) {
                toast.error('Todos los campos son obligatorios.');
                return;
            }

            if (isEditMode) {
                // **Editar curso existente**
                await actualizarCursoMatrimonio({
                    id_est_cur_mat: idEstCurMat,
                    id_curso: selectedCurso,
                    lugar: lugarCurso,
                    disertante: disertante,
                    fecha_desde: fechaDesde,
                    fecha_hasta: fechaHasta
                });

                toast.success('Curso actualizado correctamente.');
            } else {
                // **Registrar nuevo curso**
                await registrarCursoMatrimonio({
                    id_matrimonio: idMatrimonio,
                    id_curso: selectedCurso,
                    lugar: lugarCurso,
                    disertante: disertante,
                    fecha_desde: fechaDesde,
                    fecha_hasta: fechaHasta
                });

                toast.success('Curso registrado correctamente.');
            }

            setOpen(false);
            await fetchMatrimonioData(idMatrimonio); // Recargar datos

        } catch (error) {
            console.error('Error al guardar el curso:', error);
            toast.error('Error al guardar el curso.');
        }
    };


    return (
        <PageWrapper>

            <PageTitle title="Cursos del Matrimonio" />

            <Typography variant="h5" align="center">
                <b>Matrimonio:</b> {matrimonio?.ella_nombres} {matrimonio?.ella_apellidos} y {matrimonio?.el_nombres} {matrimonio?.el_apellidos}
            </Typography>

            <PageContent>

                {/* Sección filtros */}
                <Box
                    sx={{
                        display: 'flex',
                        flexDirection: 'row',
                        justifyContent: 'center',
                        alignItems: 'center',
                        border: 1,
                        borderColor: 'grey.300',
                        borderRadius: 1,
                        p: 2,
                        backgroundColor: 'white',
                        mb: 2,
                        width: '100%'
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={4}>
                            <TextField
                                fullWidth
                                label="Buscar por curso, nivel o fecha"
                                variant="outlined"
                                value={search}
                                onChange={(e) => setSearch(e.target.value)}
                            />

                        </Grid>

                        <Grid item xs={12} sm={6} md={2}>
                            <Tooltip title="Cargar nuevo curso" placement="top">
                                <Button
                                    variant="contained"
                                    size="large"
                                    style={{ height: '100%', backgroundColor: 'green' }}
                                    onClick={handleClickOpen} ><AddCircleOutlineIcon /></Button>
                            </Tooltip>

                            <Tooltip title="Descargar planilla de cursos realizados" placement="top">
                                <Button
                                    variant="contained"
                                    size="large"
                                    style={{ height: '100%', marginLeft: '10px', backgroundColor: 'blue' }}
                                    onClick={handleDownloadExcel} >
                                    <ArrowCircleDownIcon />
                                </Button>
                            </Tooltip>

                        </Grid>

                    </Grid>
                </Box>

                {filteredRows.length === 0 ? (
                    <Typography variant="h5" align="center">
                        No hay cursos registrados.
                    </Typography>
                ) : (
                    <PaginationTable
                        columns={columns}
                        rows={filteredRows}
                        getRowId={(row) => row.id_est_cur_mat} // Definir la clave única para las filas
                        renderActions={(row) => (
                            <>
                                <Tooltip title="Editar" placement="top">
                                    <EditIcon onClick={() => handleClickEdit(row)} />
                                </Tooltip>
                                <Tooltip title="Eliminar" placement="top">
                                    <DeleteIcon onClick={() => handleOpenDeleteCurso(row.id_est_cur_mat)} />
                                </Tooltip>
                            </>
                        )}
                    />
                )}


            </PageContent>

            {/* Sección para registrar curso realizado por matrimonio*/}
            <Dialog
                open={open}
                onClose={handleClose}
                fullScreen={fullScreen}
                maxWidth={maxWidth}
                keepMounted
                PaperProps={{ style: { minWidth: '500px' } }}>

                <DialogTitle>
                    {isEditMode ? "Editar curso del matrimonio" : "Registrar curso para matrimonio"}
                </DialogTitle>
                <DialogContent>
                    <DialogContentText>
                        Inserte los datos sobre el curso realizado por el matrimonio.
                    </DialogContentText>
                </DialogContent>
                <DialogContent
                    sx={{
                        display: 'flex',
                        flexDirection: 'column',
                        width: '100%',
                    }}
                >
                    <Grid container spacing={2}>
                        <Grid item xs={12} sm={6} md={6}>
                            <BasicSelect
                                label="Nivel del curso"
                                labelId="simple-select-label"
                                id="simple-select"
                                value={selectedNivel}
                                required
                                onChange={handleNivelChange}
                                options={nivelCiclo.map((nivel) => ({
                                    label: nivel.ciclo,
                                    value: nivel.id,
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <BasicSelect
                                label="Curso"
                                labelId="simple-select-label"
                                id="simple-select"
                                value={selectedCurso}
                                required
                                onChange={handleCursoChange}
                                options={cursos.map((curso) => ({
                                    label: curso.nombre_curso,
                                    value: curso.id_curso,
                                }))}
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                id="fecha-desde"
                                label="Fecha desde"
                                type="date"
                                value={fechaDesde}
                                required
                                onChange={(event) => setFechaDesde(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                id="fecha-hasta"
                                label="Fecha hasta"
                                type="date"
                                value={fechaHasta}
                                onChange={(event) => setFechaHasta(event.target.value)}
                                InputLabelProps={{
                                    shrink: true,
                                }}
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                id="lugar"
                                label="Lugar"
                                type="text"
                                value={lugarCurso}
                                required
                                onChange={(event) => setlugarCurso(event.target.value)}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>
                        <Grid item xs={12} sm={6} md={6}>
                            <TextField
                                id="disertante"
                                label="Disertante"
                                type="text"
                                value={disertante}
                                required
                                onChange={(event) => setDisertante(event.target.value)}
                                variant="outlined"
                                fullWidth
                            />
                        </Grid>


                    </Grid>

                </DialogContent>
                <DialogActions>
                    <Button onClick={handleClose}>Cancelar</Button>
                    <Button onClick={handleConfirmar} >Confirmar</Button>
                </DialogActions>
            </Dialog>

            {/* Confirmación eliminar curso */}
            <Dialog
                open={openDeleteCurso}
                onClose={handleCloseDelete}
                aria-labelledby="alert-dialog-title"
                aria-describedby="alert-dialog-description"
            >
                <DialogTitle id="alert-dialog-title">
                    {"¿Desea eliminar el curso?"}
                </DialogTitle>
                <DialogContent>
                </DialogContent>
                <DialogActions>
                    <Button onClick={handleCloseDelete}>Cancelar</Button>
                    <Button onClick={handleConfirmDelete} autoFocus>
                        Confirmar
                    </Button>
                </DialogActions>
            </Dialog>

        </PageWrapper>
    );
}

export default AsignarCursoMatrimonio;
