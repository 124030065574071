import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getMatrimonios = async (id_diocesis = null, id_base = null, id_grupo = null) => {

    try {

        const response = await axios.get(`${API_URL}/matrimonios/`, {
            params: {
                pageSize: 10000,
                id_diocesis,
                id_base,
                id_grupo
            }
        });

        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const createMatrimonio = async (dataForm) => {

    try {

        const response = await axios.post(`${API_URL}/matrimonios/create`, {
            id_grupo: dataForm.mfc.grupo.value,
            fecha_ingreso: dataForm.mfc.fecha_ingreso,
            casa_retiro: dataForm.mfc.casa_retiro,
            fecha_encuentro: dataForm.mfc.fecha_encuentro,
            tipo_mat: dataForm.mfc.tipo_matrimonio.value,
            fecha_membresia: dataForm.mfc.fecha_membresia,
            estado_marital: dataForm.matrimonio.estado_marital.value,
            aniversario: dataForm.matrimonio.aniversario,
            parroquia_boda: dataForm.matrimonio.parroquia_boda,
            direccion: dataForm.matrimonio.direccion_domicilio,
            barrio: dataForm.matrimonio.barrio_domicilio,
            ciudad: dataForm.matrimonio.ciudad_domicilio,
            id_estado_miembro: 2, // Miembro ACTIVO
            ella_nombres: dataForm.esposa.nombres,
            ella_apellidos: dataForm.esposa.apellidos,
            ella_cedula: dataForm.esposa.documento,
            ella_nacionalidad: dataForm.esposa.nacionalidad.value,
            ella_fecha_nac: dataForm.esposa.fechaNacimiento,
            ella_lugar_nac: dataForm.esposa.lugarNacimiento,
            ella_profesion: dataForm.esposa.profesion,
            ella_lugar_trabajo: dataForm.esposa.lugarTrabajo,
            ella_cel: dataForm.esposa.celular,
            ella_tel_laboral: dataForm.esposa.telLaboral,
            ella_correo: dataForm.esposa.email,
            el_nombres: dataForm.esposo.nombres,
            el_apellidos: dataForm.esposo.apellidos,
            el_cedula: dataForm.esposo.documento,
            el_nacionalidad: dataForm.esposo.nacionalidad.value,
            el_fecha_nac: dataForm.esposo.fechaNacimiento,
            el_lugar_nac: dataForm.esposo.lugarNacimiento,
            el_profesion: dataForm.esposo.profesion,
            el_lugar_trabajo: dataForm.esposo.lugarTrabajo,
            el_cel: dataForm.esposo.celular,
            el_tel_laboral: dataForm.esposo.telLaboral,
            el_correo: dataForm.esposo.email,
        });

        return response;
    } catch (error) {
        throw error;
    }
}

export const updateMatrimonio = async (matrimonioId, dataForm) => {

    try {

        const response = await axios.put(`${API_URL}/matrimonios/update/`, {
            id_matrimonio: matrimonioId,
            id_grupo: dataForm.mfc.grupo.value,
            fecha_ingreso: dataForm.mfc.fecha_ingreso,
            casa_retiro: dataForm.mfc.casa_retiro,
            fecha_encuentro: dataForm.mfc.fecha_encuentro,
            tipo_mat: dataForm.mfc.tipo_matrimonio.value,
            fecha_membresia: dataForm.mfc.fecha_membresia,
            estado_marital: dataForm.matrimonio.estado_marital.value,
            aniversario: dataForm.matrimonio.aniversario,
            parroquia_boda: dataForm.matrimonio.parroquia_boda,
            direccion: dataForm.matrimonio.direccion_domicilio,
            barrio: dataForm.matrimonio.barrio_domicilio,
            ciudad: dataForm.matrimonio.ciudad_domicilio,
            id_estado_miembro: 2,
            ella_nombres: dataForm.esposa.nombres,
            ella_apellidos: dataForm.esposa.apellidos,
            ella_cedula: dataForm.esposa.documento,
            ella_nacionalidad: dataForm.esposa.nacionalidad.value,
            ella_fecha_nac: dataForm.esposa.fechaNacimiento,
            ella_lugar_nac: dataForm.esposa.lugarNacimiento,
            ella_profesion: dataForm.esposa.profesion,
            ella_lugar_trabajo: dataForm.esposa.lugarTrabajo,
            ella_cel: dataForm.esposa.celular,
            ella_tel_laboral: dataForm.esposa.telLaboral,
            ella_correo: dataForm.esposa.email,
            el_nombres: dataForm.esposo.nombres,
            el_apellidos: dataForm.esposo.apellidos,
            el_cedula: dataForm.esposo.documento,
            el_nacionalidad: dataForm.esposo.nacionalidad.value,
            el_fecha_nac: dataForm.esposo.fechaNacimiento,
            el_lugar_nac: dataForm.esposo.lugarNacimiento,
            el_profesion: dataForm.esposo.profesion,
            el_lugar_trabajo: dataForm.esposo.lugarTrabajo,
            el_cel: dataForm.esposo.celular,
            el_tel_laboral: dataForm.esposo.telLaboral,
            el_correo: dataForm.esposo.email,
        });

        return response;
    }
    catch (error) {
        throw error;
    }
}

export const getMatrimonio = async (id) => {
    try {
        const response = await axios.get(`${API_URL}/matrimonios/datos-matrimonio/${id}`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const inativeMatrimonio = async (id_matrimonio, observacion) => {
    try {
        const response = await axios.put(`${API_URL}/matrimonios/inactivar/`, {
            id_matrimonio,
            observacion
        });
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
}
export const activeMatrimonio = async (id_matrimonio) => {
    try {
        const response = await axios.put(`${API_URL}/matrimonios/activar/`, {
            id_matrimonio
        });
        return response;
    } catch (error) {
        console.log(error);
        return null;
    }
}