import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const login = async (username, password) => {
    try {
        const response = await axios.post(`${API_URL}/auth/login`, {
                usuario: username,  
                password: password
          });

        return response;

    } catch (error) {
        return error.response ?? { status: 500, data: { mensaje: 'Error de conexión con el servidor.' } };
    }
}

export const changePassword = async (newPassword) => {
    try {
        const response = await axios.put(`${API_URL}/usuarios/change-password`, {
            newPassword
        });
        return response;
    } catch (error) {
        return null;
    }
}

export const resetPasswordDefault = async (cedula) => {
    try {
        const response = await axios.put(`${API_URL}/usuarios/reset-password-default`, {
            cedula
        });
        return response;
    } catch (error) {
        return error.response ?? { status: 500, data: { mensaje: 'Error de conexión con el servidor.' } };
    }
}