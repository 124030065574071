import React, { useEffect, useState, useCallback } from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import PaginationTable from '../../components/tables/PaginationTable';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { getAllUsersMatrimonios, assignRol } from '../../services/userService';
import { resetPasswordDefault } from '../../services/authService';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BasicSelect from '../../components/basicSelect/BasicSelect';
import RestartAltIcon from '@mui/icons-material/RestartAlt';
import useStore from '../../store/store';

function UsuariosMatrimonios() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));

  // Estados principales
  const [users, setUsers] = useState([]); // Datos originales
  const [filteredUsers, setFilteredUsers] = useState([]); // Datos filtrados para la tabla
  const [open, setOpen] = useState(false);
  const [rol, setRol] = useState('');
  const [matrimonioId, setMatrimonioId] = useState('');
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  // Estado para la confirmación de reset de contraseña
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Obtener permisos del usuario
  const { permisos = [] } = useStore();

  // Columnas de la tabla
  const columns = [
    { id: 'id_matrimonio', label: 'ID', minWidth: 10 },
    { id: 'matrimonio', label: 'Matrimonio', minWidth: 150 },
    { id: 'el_cedula', label: 'CI Esposo', minWidth: 60 },
    { id: 'diocesis', label: 'Diócesis', minWidth: 50 },
    { id: 'base', label: 'Base', minWidth: 200 },
    { id: 'grupo', label: 'Grupo', minWidth: 50 },
    { id: 'rol', label: 'Rol', minWidth: 20 },
  ];

  // Permisos para roles
  const permisosParaRoles = {
    ASIGNAR_ROL_BASE: [4, 8, 10, 12, 14],      // Dptos. Base
    ASIGNAR_ROL_DIOCESIS: [3, 7, 9, 11, 13],   // Dptos. Diócesis
    ASIGNAR_SIN_ROL: [0],                 // Opción para quitar rol
  };

  // Todos los roles
  const allRoles = [
    { label: 'Dpto. Seguimiento Base Parroquial', value: 4 },
    { label: 'Dpto. Seguimiento Diócesis', value: 3 },
    { label: 'Dpto. Formación Diócesis', value: 7 },
    { label: 'Dpto. Formación Base Parroquial', value: 8 },
    { label: 'Dpto. Comunicación Diócesis', value: 9 },
    { label: 'Dpto. Comunicación Base Parroquial', value: 10 },
    { label: 'Dpto. Servicio a la Comunidad Diócesis', value: 11 },
    { label: 'Dpto. Servicio a la Comunidad Base Parroquial', value: 12 },
    { label: 'Dpto. Espiritualidad Diócesis', value: 13 },
    { label: 'Dpto. Espiritualidad Base Parroquial', value: 14 },
    { label: 'Sin rol', value: 0 },
  ];

  // Roles permitidos
  const allowedRoles = allRoles.filter(role =>
    Object.entries(permisosParaRoles).some(
      ([permiso, roleIds]) => permisos.includes(permiso) && roleIds.includes(role.value)
    )
  );

  // Obtiene los datos de los matrimonios
  const fetchAllUsers = useCallback(async () => {
    setLoading(true);
    try {
      const users = await getAllUsersMatrimonios();
      setUsers(users);
      setFilteredUsers(users);
    } catch (error) {
      toast.error('Error al obtener los usuarios');
      console.error('Error al obtener datos:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  // Filtrado en la búsqueda
  useEffect(() => {
    if (!search.trim()) {
      setFilteredUsers(users);
      return;
    }

    const lowercasedSearch = search.toLowerCase();
    const filtered = users.filter(user =>
      ['matrimonio', 'el_cedula', 'diocesis', 'base', 'grupo', 'rol'].some(key =>
        user[key]?.toString().toLowerCase().includes(lowercasedSearch)
      )
    );
    setFilteredUsers(filtered);
  }, [search, users]);

  // Manejo del diálogo de asignación de rol
  const handleClickOpen = (id) => {
    setMatrimonioId(id);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
    setRol('');
  };

  // Asignación de rol con validaciones y actualización de la lista
  const handleChangeRol = async () => {
    if (!matrimonioId) {
      toast.error('No se ha seleccionado un matrimonio');
      return;
    }

    try {
      await assignRol(matrimonioId, rol);
      toast.success('Rol asignado correctamente');
      fetchAllUsers(); // Recargar la lista después de asignar el rol
    } catch (error) {
      toast.error('Error al asignar rol');
      console.error('Error:', error);
    }

    handleClose();
  };

  // Manejo del diálogo de confirmación de reset de contraseña
  const handleOpenResetDialog = (user) => {
    setSelectedUser(user);
    setOpenResetDialog(true);
  };

  const handleCloseResetDialog = () => {
    setOpenResetDialog(false);
    setSelectedUser(null);
  };

  const handleConfirmResetPassword = async () => {
    if (!selectedUser) return;

    try {
      await resetPasswordDefault(selectedUser.el_cedula);
      toast.success(`Contraseña reseteada para ${selectedUser.matrimonio}`);
    } catch (error) {
      toast.error('Error al resetear la contraseña');
      console.error('Error:', error);
    }

    handleCloseResetDialog();
  };  

  return (
    <PageWrapper>
      <PageTitle title="Asignación de rol de usuario" />

      <PageContent>
        {/* Buscador */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 1,
            borderColor: 'grey.300',
            borderRadius: 1,
            p: 2,
            backgroundColor: 'white',
            mb: 2,
            width: '100%'
          }}
        >
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField
                fullWidth
                label="Buscar matrimonio"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>
          </Grid>
        </Box>

        {/* Tabla con paginación */}
        <PaginationTable
          columns={columns}
          rows={filteredUsers || []}
          loading={loading}
          renderActions={(row) => (
            <>
              {permisos.includes('ASIGNAR_SIN_ROL') && (
                <Tooltip title="Asignar rol" placement="top">
                  <VerifiedUserIcon onClick={() => handleClickOpen(row.id_matrimonio)} />
                </Tooltip>
              )}
              {permisos.includes('BTN_RESET_PASS_MAT') && (
                row.rol !== 'Sin rol' && (
                  <Tooltip title="Resetear contraseña" placement="top">
                    <RestartAltIcon onClick={() => handleOpenResetDialog(row)} style={{ cursor: 'pointer', color: 'red' }} />
                  </Tooltip>
                )
              )}
            </>
          )}
        />
      </PageContent>

      {/* Modal de asignación de rol */}
      <Dialog 
        open={open} 
        onClose={handleClose} 
        fullScreen={fullScreen} 
        maxWidth="md" 
        PaperProps={{ style: { minWidth: '500px' } }}
      >
        <DialogTitle>Asignar rol a usuario</DialogTitle>
        <DialogContent>
          <DialogContentText>Seleccionar rol a asignar</DialogContentText>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <BasicSelect
                label="Rol"
                labelId="select-rol"
                id="select-rol"
                value={rol}
                onChange={(event) => setRol(event.target.value)}
                options={allowedRoles}
                sx={{ width: '100%', mt: 2 }}
              />
            </Grid>
          </Grid>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Cancelar</Button>
          <Button onClick={handleChangeRol} disabled={rol === '' || rol === null || rol === undefined}>
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

      {/* Modal de confirmación para resetear contraseña */}
      <Dialog
        open={openResetDialog}
        onClose={handleCloseResetDialog}
        fullScreen={fullScreen}
        maxWidth="sm"
      >
        <DialogTitle>Confirmación de reseteo</DialogTitle>
        <DialogContent>
          <DialogContentText>
            ¿Estás seguro de que deseas resetear la contraseña de <strong>{selectedUser?.matrimonio}</strong>?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetDialog}>Cancelar</Button>
          <Button onClick={handleConfirmResetPassword} color="error">
            Confirmar
          </Button>
        </DialogActions>
      </Dialog>

    </PageWrapper>
  );
}

export default UsuariosMatrimonios;
