import ChurchIcon from '@mui/icons-material/Church';
import FamilyRestroomIcon from '@mui/icons-material/FamilyRestroom';
import Diversity3Icon from '@mui/icons-material/Diversity3';
import EqualizerIcon from '@mui/icons-material/Equalizer';
import PictureAsPdfIcon from '@mui/icons-material/PictureAsPdf';
import AdminPanelSettingsIcon from '@mui/icons-material/AdminPanelSettings';
import GroupWorkIcon from '@mui/icons-material/GroupWork';
import SouthAmericaIcon from '@mui/icons-material/SouthAmerica';
import ImportContactsIcon from '@mui/icons-material/ImportContacts';
import SavingsIcon from '@mui/icons-material/Savings';
import useStore from '../../store/store';

const menuItems = [
    {
        text: 'Diócesis',
        icon: <SouthAmericaIcon />,
        path: '/diocesis',
        requiredPerm: 'MENU_ABM_DIOCESIS'
      },
      {
        text: 'Bases Parroquiales',
        icon: <ChurchIcon />,
        path: '/bases',
        requiredPerm: 'MENU_ABM_BASES'
      },
    {
        text: 'Grupos',
        icon: <GroupWorkIcon/>,
        path: '/grupos',
        requiredPerm: 'MENU_ABM_GRUPOS'
    },
    {
        text: 'Matrimonios',
        icon: <FamilyRestroomIcon />,
        path: '/matrimonios',
        requiredPerm: 'MENU_ABM_MATRIMONIOS'
    },
    {
        text: 'Jóvenes',
        icon: <Diversity3Icon />,
        path: '/jovenes',
        requiredPerm: 'MENU_ABM_JOVENES'
    },
    {
        text: 'Cursos',
        icon: <ImportContactsIcon />,
        path: '/cursos', 
        requiredPerm: 'MENU_ABM_CURSOS'
    },
    {
        text: 'Aportes',
        icon: <SavingsIcon />,
        path: '/aportes',  
        requiredPerm: 'MENU_APORTES'
    },
    {
        text: 'Reportes',
        icon: <EqualizerIcon />,
        path: '/reportes',  
        requiredPerm: 'MENU_REPORTES'
    },
    {
        text: 'Repositorio',
        icon: <PictureAsPdfIcon />,
        path: '/repositorio',
        requiredPerm: 'MENU_REPOSITORIO'
    },
    {
        text: 'Administración',
        icon: <AdminPanelSettingsIcon />,
        requiredPerm: 'MENU_ADMINISTRACION',
        subItems: [
            {
                text: 'Usuarios matrimonios',
                path: '/admin/usuarios-matrimonios',
                icon: <FamilyRestroomIcon />,
                requiredPerm: 'MENU_ADM_MAT'
            },
            {
                text: 'Usuarios jóvenes',
                path: '/admin/usuarios-jovenes',
                icon: <Diversity3Icon />,
                requiredPerm: 'MENU_ADM_JOV'
            },
        ]
    }
];

// Hook para obtener y filtrar el menú
const useFilteredMenuItems = () => {
    const { permisos = [] } = useStore();
  
    return menuItems
    .map(item => {
      // Filtrado de subItems si existen
      if (item.subItems) {
        const filteredSubItems = item.subItems.filter(subItem => {
          return !subItem.requiredPerm || permisos.includes(subItem.requiredPerm);
        });

        // Si no quedan subItems visibles, no mostramos el ítem padre
        if (filteredSubItems.length === 0) return null;

        return {
          ...item,
          subItems: filteredSubItems
        };
      }

      // Validación de permisos del ítem padre
      if (item.requiredPerm && !permisos.includes(item.requiredPerm)) return null;

      return item;
    })
    .filter(Boolean);
  };
  

export default useFilteredMenuItems;
