import React, { useEffect, useState, useCallback } from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import PaginationTable from '../../components/tables/PaginationTable';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { getAllUsersJovenes, asignarRolJoven } from '../../services/userService';
import { resetPasswordDefault } from '../../services/authService';
import VerifiedUserIcon from '@mui/icons-material/VerifiedUser';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import Box from '@mui/material/Box';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import BasicSelect from '../../components/basicSelect/BasicSelect';
import RestartAltIcon from '@mui/icons-material/RestartAlt';

function UsuariosJovenes() {
  const theme = useTheme();
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  
  // Estados principales
  const [users, setUsers] = useState([]); 
  const [filteredUsers, setFilteredUsers] = useState([]); 
  const [search, setSearch] = useState('');
  const [loading, setLoading] = useState(false);

  // Estado para la asignación de roles
  const [openRolDialog, setOpenRolDialog] = useState(false);
  const [rol, setRol] = useState('');
  const [jovenId, setJovenId] = useState('');

  // Estado para la confirmación de reset de contraseña
  const [openResetDialog, setOpenResetDialog] = useState(false);
  const [selectedUser, setSelectedUser] = useState(null);

  // Columnas de la tabla
  const columns = [
    { id: 'id_joven', label: 'ID', minWidth: 10 },
    { id: 'joven', label: 'Joven', minWidth: 150 },
    { id: 'cedula', label: 'CI', minWidth: 60 },
    { id: 'diocesis', label: 'Diócesis', minWidth: 50 },
    { id: 'base', label: 'Base', minWidth: 200 },
    { id: 'grupo', label: 'Grupo', minWidth: 50 },
    { id: 'rol', label: 'Rol', minWidth: 20 },
  ];  

  // Obtener datos de usuarios
  const fetchAllUsers = useCallback(async () => {
    setLoading(true);
    try {
      const users = await getAllUsersJovenes();
      setUsers(users);
      setFilteredUsers(users);
    } catch (error) {
      toast.error('Error al obtener los usuarios');
      console.error('Error al obtener datos:', error);
    } finally {
      setLoading(false);
    }
  }, []);

  useEffect(() => {
    fetchAllUsers();
  }, [fetchAllUsers]);

  // Filtrado de usuarios basado en búsqueda
  useEffect(() => {
    if (!search.trim()) {
      setFilteredUsers(users);
      return;
    }
    
    const lowercasedSearch = search.toLowerCase();
    const filtered = users.filter(user =>
      ['joven', 'cedula', 'diocesis', 'base', 'grupo', 'rol'].some(key =>
        user[key]?.toString().toLowerCase().includes(lowercasedSearch)
      )
    );
    setFilteredUsers(filtered);
  }, [search, users]);

  // Manejo del diálogo de asignación de roles
  const handleOpenRolDialog = (id) => {
    setJovenId(id);
    setOpenRolDialog(true);
  };

  const handleCloseRolDialog = () => {
    setOpenRolDialog(false);
    setRol('');
  };

  // Asignación de rol con validación
  const handleChangeRol = async () => {
    if (!jovenId) {
      toast.error('No se ha seleccionado un usuario');
      return;
    }

    try {
      await asignarRolJoven(jovenId, rol);
      toast.success('Rol asignado correctamente');
      fetchAllUsers(); // Refresca la lista de usuarios
    } catch (error) {
      toast.error('Error al asignar rol');
      console.error('Error al asignar rol:', error);
    }

    handleCloseRolDialog();
  };

  // Manejo del diálogo de confirmación de reset de contraseña
  const handleOpenResetDialog = (user) => {
    setSelectedUser(user);
    setOpenResetDialog(true);
  };

  const handleCloseResetDialog = () => {
    setOpenResetDialog(false);
    setSelectedUser(null);
  };

  const handleConfirmResetPassword = async () => {
    if (!selectedUser) return;

    try {
      await resetPasswordDefault(selectedUser.cedula);
      toast.success(`Contraseña reseteada para ${selectedUser.joven}`);
    } catch (error) {
      toast.error('Error al resetear la contraseña');
      console.error('Error:', error);
    }

    handleCloseResetDialog();
  };

  return (
    <PageWrapper>
      <PageTitle title="Asignación de rol de usuario" />

      <PageContent>
        {/* Buscador */}
        <Box sx={{ display: 'flex', justifyContent: 'center', alignItems: 'center', p: 2, backgroundColor: 'white', mb: 2, width: '100%', border: 1, borderColor: 'grey.300', borderRadius: 1 }}>
          <Grid container spacing={2}>
            <Grid item xs={12} sm={6} md={3}>
              <TextField fullWidth label="Buscar joven" variant="outlined" value={search} onChange={(e) => setSearch(e.target.value)} />
            </Grid>
          </Grid>
        </Box>

        {/* Tabla paginada */}
        <PaginationTable
          columns={columns}
          rows={filteredUsers || []}
          loading={loading}
          renderActions={(row) => (
            <>
              <Tooltip title="Asignar rol" placement="top">
                <VerifiedUserIcon onClick={() => handleOpenRolDialog(row.id_joven)} />
              </Tooltip>
              {
                row.rol !== 'Sin rol' && (
                  <Tooltip title="Resetear contraseña" placement="top">
                    <RestartAltIcon onClick={() => handleOpenResetDialog(row)} style={{ cursor: 'pointer', color: 'red' }} />
                  </Tooltip>
                )
              }
            </>
          )}
        />
      </PageContent>

      {/* Modal de asignación de rol */}
      <Dialog open={openRolDialog} onClose={handleCloseRolDialog} fullScreen={fullScreen} maxWidth="md" PaperProps={{ style: { minWidth: '500px' } }}>
        <DialogTitle>Asignar rol a usuario</DialogTitle>
        <DialogContent>
          <DialogContentText>Seleccionar rol a asignar</DialogContentText>
          <BasicSelect label="Rol" id="select-rol" value={rol} onChange={(e) => setRol(e.target.value)} options={[{ label: 'Dpto. Juvenil Diocesano - Seguimiento', value: 15 }, { label: 'Dpto. Juvenil Base Parroquial - Seguimiento', value: 16 }, { label: 'Sin rol', value: 0 }]} sx={{ width: '100%', mt: 2 }} />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseRolDialog}>Cancelar</Button>
          <Button onClick={handleChangeRol} disabled={rol === ''}>Guardar</Button>
        </DialogActions>
      </Dialog>

      {/* Modal de confirmación para resetear contraseña */}
      <Dialog open={openResetDialog} onClose={handleCloseResetDialog} fullScreen={fullScreen} maxWidth="sm">
        <DialogTitle>Confirmación de reseteo</DialogTitle>
        <DialogContent>
          <DialogContentText>¿Estás seguro de que deseas resetear la contraseña de <strong>{selectedUser?.joven}</strong>?</DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseResetDialog}>Cancelar</Button>
          <Button onClick={handleConfirmResetPassword} color="error">Confirmar</Button>
        </DialogActions>
      </Dialog>
    </PageWrapper>
  );
}

export default UsuariosJovenes;
