import React, { useState } from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import Table from '@mui/material/Table';
import TableBody from '@mui/material/TableBody';
import TableCell from '@mui/material/TableCell';
import TableContainer from '@mui/material/TableContainer';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import Paper from '@mui/material/Paper';
import Button from '@mui/material/Button';
import GetAppIcon from '@mui/icons-material/GetApp';
import TextField from '@mui/material/TextField';
import { FormControl, InputLabel, Select, MenuItem } from '@mui/material';

const archivos = [
  { nombre: 'Sistema del MFC para pruebas', tipo: 'Enlace', ruta: 'http://app-mfc.es:3001/' },
  { nombre: 'Reporte de Datos del Sistema', tipo: 'Reporte', ruta: '/files/Reporte-Datos-Sistema-MFC-Julio-2024.pdf' },
  { nombre: 'Novedades del Sistema', tipo: 'Sistema', ruta: '/files/Novedades-Sistema-MFC.pdf' },
  { nombre: 'Formulario para Reportes del Sistema', tipo: 'Enlace', ruta: 'https://forms.gle/T91REDsg3asjc6q49' },
  { nombre: 'Vídeos explicativos sobre uso del Sistema', tipo: 'Capacitación', ruta: 'https://drive.google.com/drive/folders/1XHvdOhCqjKLGAxiPDnlLq2ehGlaO1zEk?usp=drive_link' },
  { nombre: 'Estatutos del MFC Paraguay - Nuevo', tipo: 'Documentos del MFC', ruta: '/files/Estatuto.pdf' },
  { nombre: 'Reglamento del MFC Paraguay - Nuevo', tipo: 'Documentos del MFC', ruta: '/files/Reglamento.pdf' },
  { nombre: 'Formulario Seguimiento S-01 - Matrimonios', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioS-01.pdf' },
  { nombre: 'Formulario Seguimiento S-02 - Matrimonios', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioS-02.pdf' },
  { nombre: 'Formulario Seguimiento S-03 - Matrimonios', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioS-03.pdf' },
  { nombre: 'Formulario Seguimiento S-04 - Matrimonios', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioS-04.pdf' },
  { nombre: 'Formulario Seguimiento S-05 - Matrimonios', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioS-05.pdf' },
  { nombre: 'Formulario Seguimiento S-06 - Matrimonios', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioS-06.pdf' },
  { nombre: 'Formulario Seguimiento S-07 - Matrimonios', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioS-07.pdf' },
  { nombre: 'Formulario Seguimiento S-08 - Matrimonios', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioS-08.pdf' },
  { nombre: 'Formulario Seguimiento S-09 - Matrimonios', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioS-09.pdf' },
  { nombre: 'Formulario Seguimiento S-10 - Matrimonios', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioS-10.pdf' },
  { nombre: 'Formulario Seguimiento SJ-01 - Jóvenes', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioSJ-01.pdf' },
  { nombre: 'Formulario Seguimiento SJ-02 - Jóvenes', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioSJ-02.pdf' },
  { nombre: 'Formulario Seguimiento SJ-03 - Jóvenes', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioSJ-03.pdf' },
  { nombre: 'Formulario Seguimiento SJ-04 - Jóvenes', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioSJ-04.pdf' },
  { nombre: 'Formulario Seguimiento SJ-05 - Jóvenes', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioSJ-05.pdf' },
  { nombre: 'Formulario Seguimiento SJ-06 - Jóvenes', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioSJ-06.pdf' },
  { nombre: 'Formulario Seguimiento SJ-07 - Jóvenes', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioSJ-07.pdf' },
  { nombre: 'Formulario Seguimiento SJ-08 - Jóvenes', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioSJ-08.pdf' },
  { nombre: 'Formulario Seguimiento SJ-09 - Jóvenes', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioSJ-09.pdf' },
  { nombre: 'Formulario Seguimiento SJ-10 - Jóvenes', tipo: 'Formulario Seguimiento', ruta: '/files/FormularioSJ-10.pdf' },
];

function Repositorio() {
  const [busqueda, setBusqueda] = useState('');
  const [tipoSeleccionado, setTipoSeleccionado] = useState('');

  const tiposUnicos = [...new Set(archivos.map((a) => a.tipo))];

  const archivosFiltrados = archivos.filter((archivo) => {
    const coincideBusqueda =
      archivo.nombre.toLowerCase().includes(busqueda.toLowerCase()) ||
      archivo.tipo.toLowerCase().includes(busqueda.toLowerCase());

    const coincideTipo = tipoSeleccionado === '' || archivo.tipo === tipoSeleccionado;

    return coincideBusqueda && coincideTipo;
  });

  return (
    <PageWrapper>
      <PageTitle title="Repositorio de documentos" />
      <PageContent>

        <div style={{ maxWidth: '1200px', margin: '5px auto', marginBottom: 25 }}>
          <div style={{ display: 'flex', gap: 16, flexWrap: 'wrap', justifyContent: 'flex-start' }}>
            <TextField
              label="Buscar documento"
              variant="outlined"
              fullWidth
              value={busqueda}
              onChange={(e) => setBusqueda(e.target.value)}
              style={{ flex: 2, backgroundColor: 'white', width: '100%', minWidth: 500 }}
            />
            <FormControl style={{ minWidth: 200, backgroundColor: 'white' }}>
              <InputLabel id="tipo-label">Tipo de archivo</InputLabel>
              <Select
                labelId="tipo-label"
                value={tipoSeleccionado}
                label="Tipo de archivo"
                onChange={(e) => setTipoSeleccionado(e.target.value)}
              >
                <MenuItem value="">Todos</MenuItem>
                {tiposUnicos.map((tipo) => (
                  <MenuItem key={tipo} value={tipo}>{tipo}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </div>
        </div>
        <TableContainer component={Paper} style={{maxWidth: '1200px', maxHeight: '450px', margin: '5px auto' }}>
          <Table>
            <TableHead>
              <TableRow>
                <TableCell style={{ fontWeight: 'bold' }}>Nombre del archivo</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Tipo de archivo</TableCell>
                <TableCell style={{ fontWeight: 'bold' }}>Descargar</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {archivosFiltrados.map((archivo, index) => (
                <TableRow key={index}>
                  <TableCell>{archivo.nombre}</TableCell>
                  <TableCell>{archivo.tipo}</TableCell>
                  <TableCell>
                    <Button
                      variant="contained"
                      color="primary"
                      startIcon={<GetAppIcon />}
                      href={archivo.ruta}
                      target="_blank"
                      rel="noopener noreferrer"
                    >
                      Descargar
                    </Button>
                  </TableCell>
                </TableRow>
              ))}
              {archivosFiltrados.length === 0 && (
                <TableRow>
                  <TableCell colSpan={3} align="center">
                    No se encontraron archivos
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </Table>
        </TableContainer>
      </PageContent>
    </PageWrapper>
  );
}

export default Repositorio;
