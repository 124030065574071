import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getCursos = async (id_curso = null) => {
    try {
        const response = await axios.get(`${API_URL}/cursos/`, {
            params: { 
                pageSize: 1000,  
                id_curso: id_curso,
            },
          });
        return response.data.cursos;

    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getCursosRealizados = async () => {

    try {
        const response = await axios.get(`${API_URL}/cursos/cursos-realizados`, {
            params: {
                pageSize: 10000,
            }});

        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
    
}

export const registrarCursoMatrimonio = async (dataForm) => {
    try {
        const response = await axios.post(`${API_URL}/cursos/registrar-curso-matrimonio`, {
            id_matrimonio: dataForm.id_matrimonio,
            id_curso: dataForm.id_curso,
            lugar: dataForm.lugar,
            disertante: dataForm.disertante,
            fecha_desde: dataForm.fecha_desde,
            fecha_hasta: dataForm.fecha_hasta,
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const actualizarCursoMatrimonio = async (dataForm) => {
    try {
        const response = await axios.put(`${API_URL}/cursos/update-curso-matrimonio`, {
            id_est_cur_mat: dataForm.id_est_cur_mat,
            id_curso: dataForm.id_curso,
            lugar: dataForm.lugar,
            disertante: dataForm.disertante,
            fecha_desde: dataForm.fecha_desde,
            fecha_hasta: dataForm.fecha_hasta,
        });
        return response.data;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const eliminarCursoMatrimonio = async (id) => {
    try {
        const response = await axios.delete(`${API_URL}/cursos/delete-curso-matrimonio/${id}`);
        return response;
    } catch (error) {
        console.log(error);
        throw error;
    }
}

export const descargarExcelCursos = async (id_matrimonio = null) => {
    try {
        const response = await axios.get(`${API_URL}/reportes/cursos-matrimonios`, {
            params: { 
                id_matrimonio: id_matrimonio,
            },
            responseType: 'blob',
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}
