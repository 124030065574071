import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getJovenes = async (id_diocesis = null, id_base = null, id_grupo = null) => {
    
    try {

        const response = await axios.get(`${API_URL}/jovenes/`, {
            params: { 
                pageSize: 10000,
                id_diocesis,
                id_base,
                id_grupo
            }
          });

        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getJoven = async (id) => {
        
    try {

        const response = await axios.get(`${API_URL}/jovenes/datos-jovenes/${id}`);

        return response.data;
        
    } catch (error) {
        console.log(error);
        return null;
    }

}


export const createJoven = async (dataForm) => {
        
    try {

        const response = await axios.post(`${API_URL}/jovenes/create`, {
            // datos del MFC
            id_grupo_joven: dataForm.mfc.grupo.value,
            fecha_inicio: dataForm.mfc.fecha_ingreso,
            // fecha_encuentro: dataForm.mfc.fecha_encuentro,
            // casa_retiro: dataForm.mfc.casa_retiro,
            fecha_membresia: dataForm.mfc.fecha_membresia,
            // datos del joven
            nombres: dataForm.joven.nombres,
            apellidos: dataForm.joven.apellidos,
            cedula: dataForm.joven.documento,
            nacionalidad: dataForm.joven.nacionalidad.value,
            fecha_nac: dataForm.joven.fechaNacimiento,
            lugar_nacimiento: dataForm.joven.lugarNacimiento,
            cel: dataForm.joven.celular,
            correo: dataForm.joven.email,
            profesion: dataForm.joven.profesion,
            lugar_trabajo: dataForm.joven.lugarTrabajo,
            tel_laboral: dataForm.joven.telLaboral,
            // datos de la familia
            nombre_padre: dataForm.familia.nombrePadre,
            tel_padre: dataForm.familia.telPadre,
            nombre_madre: dataForm.familia.nombreMadre,
            tel_madre: dataForm.familia.telMadre,
            padres_pertenecen_mfc: dataForm.familia.padresPertenecenMFC.value,
            ciudad: dataForm.familia.ciudadDomicilio,
            barrio: dataForm.familia.barrioDomicilio,
            calle: dataForm.familia.direccionDomicilio,
            id_estado_miembro: 2, // Miembro ACTIVO
        });

        return response;

    }catch (error) {
        console.log(error);
        return null;
    }

}

export const updateJoven = async (idJoven, dataForm) => {
        
    try {

        const response = await axios.put(`${API_URL}/jovenes/update/`, {
            id_joven: idJoven,
            // datos del MFC
            id_grupo_joven: dataForm.mfc.grupo.value,
            fecha_inicio: dataForm.mfc.fecha_ingreso,
            // fecha_encuentro: dataForm.mfc.fecha_encuentro,
            // casa_retiro: dataForm.mfc.casa_retiro,
            fecha_membresia: dataForm.mfc.fecha_membresia,
            // datos del joven
            nombres: dataForm.joven.nombres,
            apellidos: dataForm.joven.apellidos,
            cedula: dataForm.joven.documento,
            nacionalidad: dataForm.joven.nacionalidad.value,
            fecha_nac: dataForm.joven.fechaNacimiento,
            lugar_nacimiento: dataForm.joven.lugarNacimiento,
            cel: dataForm.joven.celular,
            correo: dataForm.joven.email,
            profesion: dataForm.joven.profesion,
            lugar_trabajo: dataForm.joven.lugarTrabajo,
            tel_laboral: dataForm.joven.telLaboral,
            // datos de la familia
            nombre_padre: dataForm.familia.nombrePadre,
            tel_padre: dataForm.familia.telPadre,
            nombre_madre: dataForm.familia.nombreMadre,
            tel_madre: dataForm.familia.telMadre,
            padres_pertenecen_mfc: dataForm.familia.padresPertenecenMFC.value,
            ciudad: dataForm.familia.ciudadDomicilio,
            barrio: dataForm.familia.barrioDomicilio,
            calle: dataForm.familia.direccionDomicilio,
        });

        return response;

    }catch (error) {
        console.log(error);
        return null;
    }

}

export const inactivateJoven = async (idJoven, obs) => {
        
    try {

        const response = await axios.put(`${API_URL}/jovenes/inactivar/`, {
            id_joven: idJoven,
            observacion: obs,
        });

        return response;

    }catch (error) {
        console.log(error);
        return null;
    }

}

export const activateJoven = async (idJoven) => {
    try {

        const response = await axios.put(`${API_URL}/jovenes/activar-rechazar/`, {
            id_joven: idJoven,
            id_estado_miembro: 2
        });

        return response;

    }catch (error) {
        console.log(error);
        return null;
    }    
}


