import React from 'react';
import PageWrapper from '../components/pageContent/PageWrapper';
import '../App.css';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Grid from '@mui/material/Grid';

function Home() {
  return (
    <PageWrapper>
      <Box
        display="flex"
        flexDirection="column"
        alignItems="center"
        justifyContent="center"
        textAlign="center"
      >
        <Typography variant="h5" component="p" color="primary" sx={{ fontStyle: 'italic' }}>
          "No dejen que se apague la antorcha del MFC" <br></br>
          Padre Pedro Richards
        </Typography>
        <Grid
          container
          spacing={2}
          justifyContent="center"
          alignItems="center"
        >
          <Grid item xs={12} sm={12} md={6}>
            <img
              src="../img/logomfc.webp"
              alt="Logo MFC"
              className="img-responsive"
              loading='lazy'
            />
          </Grid>
        </Grid>
      </Box>
    </PageWrapper>
  );
}

export default Home;
