import { create } from "zustand";
import { persist } from 'zustand/middleware'

const useStore = create(persist(
  (set) => ({
    token: null,
    nivelAcceso: null,
    username: null,
    tipoUsuario: null,
    permisos: [],
    setToken: (token) => set({ token }),
    setNivelAcceso: (nivelAcceso) => set({ nivelAcceso }),
    setUsername: (username) => set({ username }),
    setTipoUsuario: (tipoUsuario) => set({ tipoUsuario }),
    setPermisos: (permisos) => set({ permisos }),
    clearSession: () => set(
      { 
        token: null, 
        nivelAcceso: null,
        username: null,
        tipoUsuario: null,
        permisos: null
      }
    ),
  }),
  {
    name: 'auth-storage', // nombre del item en el almacenamiento del navegador
    getStorage: () => localStorage,
  }
));

export default useStore;
