import React, { useEffect, useState, useRef } from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import BasicSelect from '../../components/basicSelect/BasicSelect';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import PaginationTable from '../../components/tables/PaginationTable';
import { getMatrimonios, inativeMatrimonio, activeMatrimonio } from '../../services/matrimoniosService';
import { getDiocesis } from '../../services/diocesisService';
import { getBases } from '../../services/baseService';
import { getGrupos } from '../../services/grupoService';
import EditIcon from '@mui/icons-material/Edit';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import CardMembershipIcon from '@mui/icons-material/CardMembership';
import { useNavigate } from 'react-router-dom';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import Tooltip from '@mui/material/Tooltip';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import { descargarCuadranteMatrimonios } from '../../services/reportMatrimoniosService';
import { getToday } from '../../utils/utils';
import useStore from '../../store/store';

function Matrimonios() {
  const theme = useTheme();
  const [matrimonios, setMatrimonios] = React.useState([]);
  const [idMatrimonioBaja, setIdMatrimonioBaja] = useState(null);
  const [idMatrimonioAlta, setIdMatrimonioAlta] = useState(null);
  const [diocesis, setDiocesis] = React.useState([]);
  const [selectedDiocesis, setSelectedDiocesis] = useState('');
  const [bases, setBases] = useState([]);
  const [selectedBase, setSelectedBase] = useState('');
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState('');
  const [selectedEstado, setSelectedEstado] = useState(2); // Solo matrimonios activos por defecto
  const [open, setOpen] = React.useState(false);
  const [openConfirmActivate, setOpenConfirmActivate] = React.useState(false);
  const [motivoBaja, setMotivoBaja] = React.useState('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [maxWidth] = React.useState('md');
  const [rows, setRows] = React.useState([]);
  const [search, setSearch] = useState('');
  const navigate = useNavigate();
  const isMounted = useRef(true);
  const { permisos = [] } = useStore();

  const columns = [
    { id: 'id_matrimonio', label: 'ID', minWidth: 5 },
    { id: 'el_cedula', label: 'CI Esposo', minWidth: 20 },
    { id: 'ella_cedula', label: 'CI Esposa', minWidth: 20 },
    { id: 'nombre_pareja', label: 'Nombre pareja', minWidth: 150 },
    { id: 'nombre_diocesis', label: 'Diócesis', minWidth: 100 },
    { id: 'nombre_base', label: 'Base Parroquial', minWidth: 100 },
    { id: 'nombre_grupo', label: 'Grupo', minWidth: 100 },
    { id: 'descripcion_estado', label: 'Estado', minWidth: 100 },

  ];

  useEffect(() => {
    isMounted.current = true;
    return () => {
      isMounted.current = false;
    };
  }, []);

  useEffect(() => {
    async function fetchData() {
      try {

        await fetchMatrimonios();
        await fetchDiocesis();
        await fetchBases();
        await fetchGrupos();

      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }

    fetchData();
  }, []);

  useEffect(() => {
    let filteredMatrimonios = matrimonios;

    if (selectedDiocesis) {
      // Filtrar por diócesis seleccionada
      filteredMatrimonios = filteredMatrimonios.filter(m => m.id_diocesis === selectedDiocesis);
    }
    if (selectedBase) {
      // Filtrar adicionalmente por base seleccionada
      filteredMatrimonios = filteredMatrimonios.filter(m => m.id_base === selectedBase);
    }
    if (selectedGrupo) {
      // Filtrar adicionalmente por grupo seleccionado
      filteredMatrimonios = filteredMatrimonios.filter(m => m.id_grupo === selectedGrupo);
    }

    if (selectedEstado !== 5) {
      // Filtrar adicionalmente por estado seleccionado
      filteredMatrimonios = filteredMatrimonios.filter(m => m.id_estado_miembro === selectedEstado);
    }

    // Filtrar matrimonios por texto de búsqueda
    if (search.trim() !== '') {
      const searchTerm = search.toLowerCase();
      filteredMatrimonios = filteredMatrimonios.filter(matrimonio =>
        matrimonio.el_cedula?.toString().includes(search) ||
        matrimonio.ella_cedula?.toString().includes(search) ||
        matrimonio.nombre_pareja?.toLowerCase().includes(searchTerm) ||
        matrimonio.nombre_diocesis?.toLowerCase().includes(searchTerm) ||
        matrimonio.nombre_base?.toLowerCase().includes(searchTerm) ||
        matrimonio.nombre_grupo?.toLowerCase().includes(searchTerm) ||
        matrimonio.descripcion_estado?.toLowerCase().includes(searchTerm)
      );
    }

    // Mapear los matrimonios filtrados a las filas de la tabla
    const mappedRows = filteredMatrimonios.map(matrimonio => ({
      id_matrimonio: matrimonio.id_matrimonio,
      el_cedula: matrimonio.el_cedula,
      ella_cedula: matrimonio.ella_cedula,
      nombre_pareja: matrimonio.nombre_pareja,
      nombre_diocesis: matrimonio.nombre_diocesis,
      nombre_base: matrimonio.nombre_base,
      nombre_grupo: matrimonio.nombre_grupo,
      descripcion_estado: matrimonio.descripcion_estado,
    }));

    setRows(mappedRows);

  }, [matrimonios, selectedDiocesis, selectedBase, selectedGrupo, selectedEstado, search]);

  const fetchMatrimonios = async () => {
    try {
      const response = await getMatrimonios();
      setMatrimonios(response.matrimonios);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const fetchDiocesis = async () => {
    try {
      const response = await getDiocesis();
      setDiocesis(response.diocesis);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handleDiocesisChange = (event) => {
    let id_diocesis = event.target.value;
    setSelectedDiocesis(id_diocesis);
    fetchBases(id_diocesis);
  };

  const fetchBases = async (id_diocesis = null, id_base = null) => {
    try {
      const response = await getBases(id_diocesis, id_base);
      setBases(response.bases);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handleBaseChange = (event) => {
    let id_base = event.target.value;
    setSelectedBase(id_base);
    fetchGrupos(id_base);
  };

  const fetchGrupos = async (id_base = null, id_grupo = null) => {
    try {
      const response = await getGrupos(id_base, id_grupo);
      setGrupos(response.grupos);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handleGrupoChange = (event) => {
    let id_grupo = event.target.value;
    setSelectedGrupo(id_grupo);
  };

  const handleEstadoChange = (event) => {
    let id_estado = event.target.value;
    setSelectedEstado(id_estado);
  };

  const openViewMatrimonios = () => {
    navigate('/matrimonio-form');
  }

  const handleEditClick = (idMatrimonio) => {
    navigate('/matrimonio-form', { state: { idMatrimonio } });
  };

  const handleAssignCourse = (idMatrimonio) => {
    if (!idMatrimonio || !isMounted.current) {
      toast.error('Ocurrió un error al acceder a los datos del matrimonio');
      return;
    }

    setTimeout(() => {
      navigate('/asignar-curso-matrimonio', { state: { idMatrimonio } });
    }, 0);
  };

  const handleBajaMatrimonio = async () => {
    if (idMatrimonioBaja) {
      await inativeMatrimonio(idMatrimonioBaja, motivoBaja);
      await fetchMatrimonios();
      toast.success('Matrimonio dado de baja');
    }
    setOpen(false);
    setIdMatrimonioBaja(null);
    setMotivoBaja('');
  }

  const handleActivate = async () => {
    if (idMatrimonioAlta) {
      await activeMatrimonio(idMatrimonioAlta);
      await fetchMatrimonios();
      toast.success('Matrimonio activado');
    }
    setOpenConfirmActivate(false);
    setIdMatrimonioAlta(null);
  }

  const handleClickOpen = (idMatrimonio) => {
    console.log('Baja de matrimonio con id: ', idMatrimonio);
    setIdMatrimonioBaja(idMatrimonio);
    setOpen(true);
  };

  const handleClickOpenActivate = (idMatrimonio) => {
    console.log('Activar matrimonio con id: ', idMatrimonio);
    setIdMatrimonioAlta(idMatrimonio);
    setOpenConfirmActivate(true);
  }

  const handleClose = () => {
    setOpen(false);
  };

  const handleCloseActivate = () => {
    setOpenConfirmActivate(false);
  }

  const handleDownloadExcelCuadrante = async () => {
    try {
      const response = await descargarCuadranteMatrimonios(selectedDiocesis, selectedBase, selectedGrupo, selectedEstado);
      const url = window.URL.createObjectURL(new Blob([response]));
      const link = document.createElement('a');
      document.body.appendChild(link);
      link.href = url;
      link.download = 'cuadrante-matrimonios.xlsx';
      link.click();
      link.remove();
    } catch (error) {
      console.error('Error al descargar el archivo:', error);
      toast.error('Error al descargar el archivo.');
    }
  };


  return (
    <PageWrapper>

      <PageTitle title="Matrimonios" />

      <PageContent>

        {/* Sección filtros */}
        <Box
          sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 1,
            borderColor: 'grey.300',
            borderRadius: 1,
            p: 2,
            backgroundColor: 'white',
            mb: 2,
            width: '100%'
          }}
        >

          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={2}>
              <TextField
                fullWidth
                label="Buscar"
                variant="outlined"
                value={search}
                onChange={(e) => setSearch(e.target.value)}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <BasicSelect
                label="Diócesis"
                labelId="simple-select-label"
                id="simple-select"
                value={selectedDiocesis}
                onChange={handleDiocesisChange}
                options={diocesis.map((diocesis) => ({
                  label: diocesis.nombre_diocesis,
                  value: diocesis.id_diocesis,
                }))}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <BasicSelect
                label="Bases Parroquial"
                labelId="simple-select-label2"
                id="simple-select2"
                value={selectedBase}
                onChange={handleBaseChange}
                options={bases.map((bases) => ({
                  label: bases.nombre_base,
                  value: bases.id_base,
                }))}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <BasicSelect
                label="Grupos"
                labelId="simple-select-label"
                id="simple-select"
                value={selectedGrupo}
                onChange={handleGrupoChange}
                options={grupos.map((grupos) => ({
                  label: grupos.nombre_grupo,
                  value: grupos.id_grupo,
                }))}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <BasicSelect
                label="Estado"
                labelId="simple-select-label"
                id="simple-select"
                value={selectedEstado}
                onChange={handleEstadoChange}
                options={[
                  { value: 5, label: 'Todos' },
                  { value: 2, label: 'Activo' },
                  { value: 3, label: 'Inactivo' },
                  // { value: 1, label: 'Pendiente'},
                  // { value: 4, label: 'Traslado'}
                ]}
              />
            </Grid>


            <Grid item xs={12} sm={6} md={2}>
              <Tooltip title="Cargar nuevo matrimonio" placement="top">
                <Button variant="contained" size="large" style={{ height: '100%', marginLeft: '10px', backgroundColor: 'green' }} onClick={openViewMatrimonios} ><AddCircleOutlineIcon /></Button>
              </Tooltip>

              <Tooltip title="Descargar cuadrante" placement="top">
                <Button variant="contained" size="large" style={{ height: '100%', marginLeft: '10px', backgroundColor: 'blue' }} onClick={handleDownloadExcelCuadrante} ><ArrowCircleDownIcon /></Button>
              </Tooltip>
            </Grid>
          </Grid>

        </Box>

        {/* Sección tabla */}
        <PaginationTable
          columns={columns}
          rows={rows}
          getRowId={(row) => row.id_matrimonio}
          renderActions={(row) => (
            <>
              {row.descripcion_estado === 'ACTIVO' && (
                <>
                  <Tooltip title="Editar" placement="top">
                    <EditIcon onClick={() => handleEditClick(row.id_matrimonio)} />
                  </Tooltip>
                  {permisos.includes('BTN_INACTIVAR_MAT') && (
                    <Tooltip title="Inactivar" placement="top">
                      <ArrowDownwardIcon leIcon onClick={() => handleClickOpen(row.id_mat)} />
                    </Tooltip>
                  )}
                  {permisos.includes('BTN_AGREGAR_CURSO_MAT') && (
                    <Tooltip title="Agregar curso" placement="top">
                      <CardMembershipIcon onClick={() => handleAssignCourse(row.id_matrimonio)} />
                    </Tooltip>
                  )}
                </>
              )}
              {row.descripcion_estado === 'INACTIVO' &&
                <Tooltip title="Activar" placement="top">
                  <ArrowUpwardIcon onClick={() => handleClickOpenActivate(row.id_matrimonio)} />
                </Tooltip>
              }

            </>
          )}
        />

        {/* Sección para dar de baja matrimonio*/}
        <Dialog
          open={open}
          onClose={handleClose}
          fullScreen={fullScreen}
          maxWidth={maxWidth}
          PaperProps={{ style: { minWidth: '500px' } }}>

          <DialogTitle>Baja de Matrimonio</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Seleccionar datos para la baja del matrimonio
            </DialogContentText>
          </DialogContent>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
          >
            <Grid container spacing={1}>
              <Grid item xs={12} mt={2}>
                <TextField
                  id="fecha-baja"
                  label="Fecha baja"
                  type="date"
                  defaultValue={getToday()}
                  onChange={(event) => { console.log(event.target.value) }}
                  InputLabelProps={{
                    shrink: true,
                  }}
                  fullWidth
                />
              </Grid>
              <Grid item xs={12} mt={2}>
                <TextField
                  id="motivo"
                  label="Motivo"
                  value={motivoBaja}
                  onChange={(event) => setMotivoBaja(event.target.value)}
                  variant="outlined"
                  fullWidth
                />
              </Grid>
            </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleBajaMatrimonio}>Confirmar</Button>
          </DialogActions>
        </Dialog>

        {/* Confirmación activación de matrimonio */}
        <Dialog
          open={openConfirmActivate}
          onClose={handleCloseActivate}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"¿Desea activar el matrimonio?"}
          </DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseActivate}>Cancelar</Button>
            <Button onClick={handleActivate} autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

      </PageContent>
    </PageWrapper>

  );
}

export default Matrimonios;
