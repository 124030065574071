import React from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import Box from '@mui/material/Box';
import PaginationTable from '../../components/tables/PaginationTable';
import { getBases, createBase, updateBase } from '../../services/baseService';
import { getDiocesis } from '../../services/diocesisService';
import { useEffect, useState } from 'react';
import BasicSelect from '../../components/basicSelect/BasicSelect';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import TextField from '@mui/material/TextField';
import Dialog from '@mui/material/Dialog';
import DialogActions from '@mui/material/DialogActions';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogTitle from '@mui/material/DialogTitle';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';

function Bases() {
  
  // Variables para filtros
  const [diocesis, setDiocesis] = useState([]);
  const [selectedDiocesis, setSelectedDiocesis] = useState('');
  const [bases, setBases] = useState([]);
  const [selectedBase, setSelectedBase] = useState('');
  const [rows, setRows] = useState([]);

  // Variables para carga/edición datos de Bases
  const theme = useTheme();
  const [statusBase, setStatusBase] = useState(true);
  const [diocesisBase, setDiocesisBase] = useState('');
  const [nombreBase, setNombreBase] = useState('');
  const [open, setOpen] = React.useState(false);
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [maxWidth] = React.useState('md');
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [isEditMode, setIsEditMode] = useState(false); 
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);


  const columns = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'nombre_diocesis', label: 'Diócesis', minWidth: 200 },
    { id: 'nombre_base', label: 'Base Parroquial', minWidth: 300 }
  ];
  


  useEffect(() => {
    async function fetchData() {
      try {
        await fetchDiocesis();

        await fetchBases();

      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }
  
    fetchData();
  }, []);
  

  useEffect(() => {
    const newRows = bases.map((base) => ({
      id: base.id_base,
      nombre_diocesis: base.diocesis.nombre_diocesis,
      nombre_base: base.nombre_base,
    }));
    setRows(newRows);
  }, [bases]);   

  useEffect(() => {
      if (diocesisBase && nombreBase.trim() !== '') {
          setIsConfirmEnabled(true);
      } else {
          setIsConfirmEnabled(false);
      }
  }, [diocesisBase, nombreBase]);


  const fetchDiocesis = async () => {
    try {
      const response = await getDiocesis();
      setDiocesis(response.diocesis);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const fetchBases = async (id_diocesis = null, id_base = null) => {
    try {
      const response = await getBases(id_diocesis, id_base);
      setBases(response.bases);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handleDiocesisChange = (event) => {
    let id_diocesis = event.target.value;
    setSelectedDiocesis(id_diocesis);
    fetchBases(id_diocesis);
  };

  const handleDiocesisBaseChange = (event) => {
    let id_diocesis = event.target.value;
    setDiocesisBase(id_diocesis);
  };

  const handleBaseChange = (event) => {
    let id_base = event.target.value;
    setSelectedBase(id_base);
    
    if (id_base) {
      const filteredBases = bases.filter(base => base.id_base === parseInt(id_base));
      setBases(filteredBases);
    } else {
      fetchBases(selectedDiocesis);
    }
  };

  const handleNombreBaseChange = (event) => {
    let nombre_base = event.target.value;
    setNombreBase(nombre_base);
  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };  

  const handleConfirmAction = () => {
    if (isEditMode) {
      handleEditBase(selectedBase);
    } else {
      handleCreateBase();
    }
  };  

  const handleOpenCreateBase = () => {
    resetForm();
    handleClickOpen();
    setDialogTitle('Nueva Base Parroquial');
    setDialogDescription('Ingrese los datos de la nueva base parroquial.');
    setIsEditMode(false);
  };

  const handleCreateBase = async () => {
    try {
      let data = {
        nombre_base: nombreBase,
        id_diocesis: diocesisBase,
        estado: statusBase
      };
      const response = await createBase(data);
      toast.success('¡Nueva Base Parroquial creada exitosamente!');
      setOpen(false);
      resetForm();
      await fetchBases();
      console.log(response);
    } catch (error) {
      toast.error('¡Error al crear nueva Base Parroquial!');
      console.error('Error al crear nueva Base:', error);
    }
  };

  const handleOpenEditBase = (id) => {
    try{
      handleClickOpen();
      setDialogTitle('Editar Base Parroquial');
      setDialogDescription('Ingrese los datos a actualizar de la base parroquial.');
      setIsEditMode(true);

      const base = bases.filter(base => base.id_base === id);
      
      setStatusBase(base[0].estado === 1 ? true : false);
      setDiocesisBase(base[0].id_diocesis);
      setNombreBase(base[0].nombre_base);
      setSelectedBase(base[0].id_base);
    }catch(error){
      console.error('Error al obtener datos:', error);
    }
  };  

  const handleEditBase = async (id) => {
    try {
      let data = {
        id_base: id,
        nombre_base: nombreBase,
        newEstado: statusBase
      };
      const response = await updateBase(data);
      toast.success('¡Base Parroquial actualizada exitosamente!');
      setOpen(false);
      resetForm();
      await fetchBases();
      console.log(response);
    } catch (error) {
      toast.error('¡Error al actualizar Base Parroquial!');
      console.error('Error al actualizar Base:', error);
    }
  };

  const handleStatusBaseChange = (event) => {
    let status = event.target.value;
    setStatusBase(status);
  }

  const resetForm = () => {
    setStatusBase(true);
    setDiocesisBase('');
    setNombreBase('');
  };

  return (
    <PageWrapper>

      <PageTitle title="Bases Parroquiales"/>

      <PageContent>

        {/* Sección filtros */}
        <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 1,
            borderColor: 'grey.300',
            borderRadius: 1,
            p: 2,
            backgroundColor: 'white',
            mb: 2,
            width: '100%'
        }}
        >

          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={3}>
              <BasicSelect
                label="Diócesis"
                labelId="simple-select-label"
                id="simple-select"
                value={selectedDiocesis}
                onChange={handleDiocesisChange}
                options={diocesis.map((diocesis) => ({
                  label: diocesis.nombre_diocesis,
                  value: diocesis.id_diocesis,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              <BasicSelect
                label="Bases Parroquial"
                labelId="simple-select-label2"
                id="simple-select2"
                value={selectedBase}
                onChange={handleBaseChange}
                options={bases.map((bases) => ({
                  label: bases.nombre_base,
                  value: bases.id_base,
                }))}
              />
            </Grid>
            <Grid item xs={12} sm={6} md={3}>
              {/* <Button variant="contained" size="large" style={{ height: '100%',  }}><SearchIcon /></Button> */}
              <Button 
                variant="contained" 
                size="large" 
                style={{ 
                  height: '100%', 
                  width: '100%',
                  backgroundColor: 'green' 
                }} 
                onClick={handleOpenCreateBase}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Grid>
          </Grid>
          
        </Box>

        {/* Sección tabla */}
        <PaginationTable
          columns={columns}
          rows={rows}
          renderActions={(row) => (
            <>
              <Button variant="outlined" onClick={() => handleOpenEditBase(row.id)}>
                <EditIcon/>
              </Button>
            </>
          )}
        />
        {/* Sección carga/edición datos de Bases */}
        <Dialog 
          open={open} 
          onClose={handleClose} 
          fullScreen={fullScreen} 
          maxWidth={maxWidth} 
          PaperProps={{ style: { minWidth: '200px'} }}
        >
          <DialogTitle>{ dialogTitle }</DialogTitle>
          <DialogContent>
            <DialogContentText>
              { dialogDescription }
            </DialogContentText>
          </DialogContent>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <BasicSelect
                    label="Estado"
                    labelId="select-status"
                    id="select-status"
                    value={statusBase}
                    onChange={handleStatusBaseChange}
                    options={[
                      { label: 'Activo', value: true },
                      { label: 'Inactivo', value: false },
                    ]}
                    sx={{ width: '100%', mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <BasicSelect
                    label="Diócesis"
                    labelId="simple-select-label"
                    id="simple-select"
                    value={diocesisBase}
                    onChange={handleDiocesisBaseChange}
                    options={diocesis.map((diocesis) => ({
                      label: diocesis.nombre_diocesis,
                      value: diocesis.id_diocesis,
                    }))}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <TextField
                    id="base"
                    label="Nombre Base Parroquial"
                    value={nombreBase}
                    onChange={handleNombreBaseChange}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

              </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleConfirmAction} disabled={!isConfirmEnabled}>Confirmar</Button>
          </DialogActions>
        </Dialog>

      </PageContent>

    </PageWrapper>
  );
}

export default Bases;
