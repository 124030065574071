import * as React from 'react';
import { styled, useTheme } from '@mui/material/styles';
import Box from '@mui/material/Box';
import MuiDrawer from '@mui/material/Drawer';
import MuiAppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import List from '@mui/material/List';
import CssBaseline from '@mui/material/CssBaseline';
import Typography from '@mui/material/Typography';
import Divider from '@mui/material/Divider';
import IconButton from '@mui/material/IconButton';
import Menu from '@mui/material/Menu';
import MenuItem from '@mui/material/MenuItem';
import MenuIcon from '@mui/icons-material/Menu';
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft';
import ChevronRightIcon from '@mui/icons-material/ChevronRight';
import ListItem from '@mui/material/ListItem';
import ListItemButton from '@mui/material/ListItemButton';
import ListItemIcon from '@mui/material/ListItemIcon';
import ListItemText from '@mui/material/ListItemText';
import { useNavigate } from 'react-router-dom';
import Tooltip from '@mui/material/Tooltip';
import AccountCircle from '@mui/icons-material/AccountCircle';
import MoreIcon from '@mui/icons-material/MoreVert';
import useStore from '../../store/store';
import useFilteredMenuItems from '../../routes/menu/menuItems';
import { ExpandLess, ExpandMore } from '@mui/icons-material';
import { Collapse } from '@mui/material';
import TestingBanner from '../testingBanner/TestingBanner';


const drawerWidth = 240;

const openedMixin = (theme) => ({
  width: drawerWidth,
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.enteringScreen,
  }),
  overflowX: 'hidden',
});

const closedMixin = (theme) => ({
  transition: theme.transitions.create('width', {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  overflowX: 'hidden',
  width: `calc(${theme.spacing(7)} + 1px)`,
  [theme.breakpoints.up('sm')]: {
    width: `calc(${theme.spacing(8)} + 1px)`,
  },
});

const DrawerHeader = styled('div')(({ theme }) => ({
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'flex-end',
  padding: theme.spacing(0, 1),
  // necessary for content to be below app bar
  ...theme.mixins.toolbar,
}));

const AppBar = styled(MuiAppBar, {
  shouldForwardProp: (prop) => prop !== 'open',
})(({ theme, open }) => ({
  zIndex: theme.zIndex.drawer + 1,
  transition: theme.transitions.create(['width', 'margin'], {
    easing: theme.transitions.easing.sharp,
    duration: theme.transitions.duration.leavingScreen,
  }),
  ...(open && {
    marginLeft: drawerWidth,
    width: `calc(100% - ${drawerWidth}px)`,
    transition: theme.transitions.create(['width', 'margin'], {
      easing: theme.transitions.easing.sharp,
      duration: theme.transitions.duration.enteringScreen,
    }),
  }),
}));

const Drawer = styled(MuiDrawer, { shouldForwardProp: (prop) => prop !== 'open' })(
  ({ theme, open }) => ({
    width: drawerWidth,
    flexShrink: 0,
    whiteSpace: 'nowrap',
    boxSizing: 'border-box',
    ...(open && {
      ...openedMixin(theme),
      '& .MuiDrawer-paper': openedMixin(theme),
    }),
    ...(!open && {
      ...closedMixin(theme),
      '& .MuiDrawer-paper': closedMixin(theme),
    }),
  }),
);

export default function NavBar({ children }) {
  const navigate = useNavigate();
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);
  const [openSubMenu, setOpenSubMenu] = React.useState({});

  const [anchorEl, setAnchorEl] = React.useState(null);
  const [mobileMoreAnchorEl, setMobileMoreAnchorEl] = React.useState(null);

  const isMenuOpen = Boolean(anchorEl);
  const isMobileMenuOpen = Boolean(mobileMoreAnchorEl);
  const { clearSession } = useStore();
  const filteredMenuItems = useFilteredMenuItems();

  const { username } = useStore();

  const handleDrawerOpen = () => {
    setOpen(true);
  };

  const handleDrawerClose = () => {
    setOpen(false);
  };

  const handleItemClick = (path) => {
    navigate(path); // Redirige a la ruta correspondiente
  };  

  const handleProfileMenuOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleMobileMenuClose = () => {
    setMobileMoreAnchorEl(null);
  };

  const handleMenuClose = () => {
    setAnchorEl(null);
    handleMobileMenuClose();
  };

  const handleMobileMenuOpen = (event) => {
    setMobileMoreAnchorEl(event.currentTarget);
  };

  const openProfile = () => {
    navigate('/perfil');
    handleMenuClose();
  };

  const openChangePassword = () => {
    navigate('/cambiar-clave');
    handleMenuClose();
  };

  const handleSubMenuToggle = (text) => {
    setOpenSubMenu((prev) => ({
        ...prev,
        [text]: !prev[text],
    }));
  };

  const closeSession = () => {

    // Limpia el token del estado global de Zustand
    clearSession();

    // Elimina el token del almacenamiento local o de la sesión
    // localStorage.removeItem('auth-token'); // Elimina el token del almacenamiento local

    // Navega a la página de inicio de sesión
    navigate('/');

    // Cierra el menú
    handleMenuClose();
  };

  const openMain = () => {
    navigate('/home');
  };

  const menuId = 'primary-search-account-menu';

  const renderMenu = (
    <Menu
      anchorEl={anchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={menuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMenuOpen}
      onClose={handleMenuClose}
    >
      {/* <MenuItem onClick={openProfile}>Perfil</MenuItem> */}
      <MenuItem>Usuario: {username}</MenuItem>
      <MenuItem onClick={openChangePassword}>Cambiar contraseña</MenuItem>
      <MenuItem onClick={closeSession}>Salir</MenuItem>
    </Menu>
  ); 

  const mobileMenuId = 'primary-search-account-menu-mobile';
  const renderMobileMenu = (
    <Menu
      anchorEl={mobileMoreAnchorEl}
      anchorOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      id={mobileMenuId}
      keepMounted
      transformOrigin={{
        vertical: 'top',
        horizontal: 'right',
      }}
      open={isMobileMenuOpen}
      onClose={handleMobileMenuClose}
    >
      <MenuItem onClick={handleProfileMenuOpen}>
        <IconButton
          size="large"
          aria-label="account of current user"
          aria-controls="primary-search-account-menu"
          aria-haspopup="true"
          color="inherit"
        >
          <AccountCircle />
        </IconButton>
        <p>Profile</p>
      </MenuItem>
    </Menu>
  );  

  return (
    <>
      <Box sx={{ display: 'flex' }}>
        <CssBaseline />
        <AppBar position="fixed" open={open}>
          <Toolbar>
            <IconButton
              color="inherit"
              aria-label="open drawer"
              onClick={handleDrawerOpen}
              edge="start"
              sx={{
                marginRight: 5,
                ...(open && { display: 'none' }),
              }}
            >
              <MenuIcon />
            </IconButton>
            <Typography variant="h6" noWrap component="div" onClick={openMain}>
              Sistema MFC <TestingBanner />
            </Typography>

            <Box sx={{ flexGrow: 1 }} />
            <Box sx={{ display: { xs: 'none', md: 'flex' } }}>
              <IconButton
                size="large"
                edge="end"
                aria-label="account of current user"
                aria-controls={menuId}
                aria-haspopup="true"
                onClick={handleProfileMenuOpen}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </Box>
            <Box sx={{ display: { xs: 'flex', md: 'none' } }}>
              <IconButton
                size="large"
                aria-label="show more"
                aria-controls={mobileMenuId}
                aria-haspopup="true"
                onClick={handleMobileMenuOpen}
                color="inherit"
              >
                <MoreIcon />
              </IconButton>
            </Box>
          </Toolbar>
          {renderMobileMenu}
          {renderMenu}
        </AppBar>
        <Drawer variant="permanent" open={open}>
          <DrawerHeader>
            <IconButton onClick={handleDrawerClose}>
              {theme.direction === 'rtl' ? <ChevronRightIcon /> : <ChevronLeftIcon />}
            </IconButton>
          </DrawerHeader>
          <Divider />
          {/* Listado de accesos a menús */}
          <List>
            {filteredMenuItems.map((item) => (
                <React.Fragment key={item.text}>
                    <ListItem disablePadding sx={{ display: 'block' }}>
                        <ListItemButton
                            sx={{
                                minHeight: 48,
                                justifyContent: open ? 'initial' : 'center',
                                px: 2.5,
                                display: 'flex',
                                alignItems: 'center',
                            }}
                            onClick={() => item.subItems ? handleSubMenuToggle(item.text) : handleItemClick(item.path)}
                        >
                            {/* Ícono del Menú */}
                            <Tooltip title={!open ? item.text : ''} arrow placement="right">
                              <ListItemIcon
                                  sx={{
                                      minWidth: 40, // 🔹 Mantiene el mismo ancho para todos los iconos
                                      justifyContent: 'center',
                                  }}
                              >
                                  {item.icon}
                              </ListItemIcon>
                            </Tooltip>

                            {/* Texto del Menú */}
                            <ListItemText primary={item.text} sx={{ opacity: open ? 1 : 0 }} />

                            {/* Icono de Expandir si tiene submenú */}
                            {item.subItems && open && (
                                <ListItemIcon sx={{ minWidth: 32, justifyContent: 'center' }}>
                                    {openSubMenu[item.text] ? <ExpandLess /> : <ExpandMore />}
                                </ListItemIcon>
                            )}
                        </ListItemButton>
                    </ListItem>

                    {/* Submenú */}
                    {item.subItems && (
                        <Collapse in={openSubMenu[item.text]} timeout="auto" unmountOnExit>
                            <List component="div" disablePadding>
                                {item.subItems.map((subItem) => (
                                    <Tooltip title={!open ? subItem.text : ''} arrow placement="right" key={subItem.text}>
                                      <ListItemButton
                                          key={subItem.text}
                                          sx={{
                                              pl: open ? 4 : 3, // 🔹 Ajusta el padding dinámicamente
                                              display: 'flex',
                                              alignItems: 'center',
                                          }}
                                          onClick={() => handleItemClick(subItem.path)}
                                      >
                                          <ListItemIcon
                                              sx={{
                                                  minWidth: 40, // 🔹 Mantiene alineación
                                                  justifyContent: 'center',
                                              }}
                                          >
                                              {subItem.icon}
                                          </ListItemIcon>
                                          <ListItemText primary={subItem.text} sx={{ opacity: open ? 1 : 0 }} />
                                      </ListItemButton>
                                    </Tooltip>
                                ))}
                            </List>
                        </Collapse>
                    )}
                </React.Fragment>
            ))}
          </List>




          <Divider />
        </Drawer>
        <Box component="main" sx={{ flexGrow: 1, p: 3 }}>
          {/* <DrawerHeader /> */}
          children
        </Box>
      </Box>
    </>
  );
}
