import React from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import { Box, Button, Grid, Paper, TextField } from '@mui/material';
import { useNavigate } from 'react-router-dom';
import { toast } from 'react-hot-toast';
import { changePassword } from '../../services/authService';
import useStore from '../../store/store';

function ChangePassowrd() {

  const navigate = useNavigate ();
  const { clearSession } = useStore();

  const handleChangePassword = async (event) => {
    event.preventDefault();
    const data = new FormData(event.currentTarget);
    let newPassword = data.get('newPassword');
    let repeatPassword = data.get('repeatPassword');

    if (!newPassword || !repeatPassword) {
      toast.error('Todos los campos son obligatorios');
      return;
    }

    if (newPassword !== repeatPassword) {
      toast.error('Las contraseñas no coinciden');
      return;
    }

    // Verificar minimo de caracteres
    if (newPassword.length < 6) {
      toast.error('¡La contraseña debe tener al menos 6 caracteres!');
      return;
    }

    try {
      // Limpia el estado de la sesión antes de intentar iniciar sesión
      let response = await changePassword(newPassword);
      if (response.status === 200) {
        toast.success('Contrasena cambiada con exito');
        clearSession();
        navigate('/');
      }

    } catch (error) {
      // Manejo de errores en caso de que la autenticación falle
      console.error('Error al obtener token de autenticación', error);
      toast.error('Error al cambiar la contrasena');
    }
  };

  return (
    <PageWrapper>

      <PageTitle title="Cambiar contraseña"/>

      <PageContent>

      <Box sx={{marginTop: 12}}>
                <Grid container justifyContent="center">
                <Grid
                    item
                    xs={12}
                    sm={8}
                    md={4}
                    component={Paper}
                    elevation={6}
                    square
                >
                    <Box
                    sx={{
                        my: 4,
                        mx: 4,
                        display: "flex",
                        flexDirection: "column",
                        alignItems: "center",
                    }}
                    >
                    <Box
                        component="form"
                        noValidate
                        onSubmit={handleChangePassword}
                        sx={{ mt: 1 }}
                    >

                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="newPassword"
                          label="Nueva contraseña"
                          type="password"
                          id="newPassword"
                        />
                        <TextField
                          margin="normal"
                          required
                          fullWidth
                          name="repeatPassword"
                          label="Repetir nueva contraseña"
                          type="password"
                          id="repeatPassword"
                        />

                        <Button
                        type="submit"
                        fullWidth
                        variant="contained"
                        sx={{ mt: 3 }}
                        >
                        Cambiar
                        </Button>
                    </Box>
                    </Box>
                </Grid>
                </Grid>
            </Box>
      </PageContent>

    </PageWrapper>
  );
}

export default ChangePassowrd;
