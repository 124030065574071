import React from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import Box from '@mui/material/Box';
import { getDiocesis, createDiocesis, updateDiocesis } from '../../services/diocesisService';
import { useEffect, useState } from 'react';
import BasicSelect from '../../components/basicSelect/BasicSelect';
import PaginationTable from '../../components/tables/PaginationTable';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';


function Diocesis() {
  const theme = useTheme();
  const [diocesis, setDiocesis] = useState([]);
  const [selectedDiocesis, setSelectedDiocesis] = useState('');
  const [rows, setRows] = useState([]);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [statusDiocesis, setStatusDiocesis] = useState(true);
  const [diocesisName, setDiocesisName] = useState('');
  const [nomenclatura, setNomenclatura] = useState('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [maxWidth] = React.useState('md');


  const columns = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'nombre_diocesis', label: 'Diócesis', minWidth: 200 },
    { id: 'nomeclatura', label: 'Nomenclatura', minWidth: 200}
  ];  

  useEffect(() => {
    async function fetchData() {
      try {

        await fetchDiocesis();

      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }
  
    fetchData();
  }, []);  

  useEffect(() => {
    let filteredDiocesis = diocesis;

    if (selectedDiocesis) {
      filteredDiocesis = diocesis.filter(d => d.id_diocesis === selectedDiocesis);
    }

    const newRows = filteredDiocesis.map((diocesis) => ({
      id: diocesis.id_diocesis,
      nombre_diocesis: diocesis.nombre_diocesis,
      nomeclatura: diocesis.nomenclatura,
    }));

    setRows(newRows);
  }, [diocesis, selectedDiocesis]);
   
  useEffect(() => {
    if (diocesisName && nomenclatura) {
        setIsConfirmEnabled(true);
    } else {
        setIsConfirmEnabled(false);
    }
}, [diocesisName, nomenclatura]);  

  const fetchDiocesis = async () => {
    try {
      const response = await getDiocesis();
      setDiocesis(response.diocesis);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };  

  const handleDiocesisChange = async (event) => {
    let id_diocesis = event.target.value;
    setSelectedDiocesis(id_diocesis);    
  };  

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }; 

  const handleConfirmAction = () => {
    if (isEditMode) {
      handleEditDiocesis(selectedDiocesis);
    } else {
      handleCreateDiocesis();
    }
  }; 

  const openCreateDiocesis = async () => {
    resetForm();
    handleClickOpen();
    setDialogTitle('Nueva Diócesis');
    setDialogDescription('Ingrese los datos de la nueva base Diócesis.');
    setIsEditMode(false);
  };

  const handleCreateDiocesis = async () => {
    try {

      const response = await createDiocesis(diocesisName, nomenclatura, statusDiocesis);
      console.log('Respuesta: ', response);
      handleClose();
      await fetchDiocesis();
      resetForm();
      toast.success('¡Nueva Diócesis creada exitosamente!');

    } catch (error) {
      toast.error('¡Error al crear Diócesis!');
      console.error('Error al crear Diócesis:', error);
    }
  };

  const openEditDiocesis = async (id_diocesis) => {
    const diocesisEdit = diocesis.find(d => d.id_diocesis === id_diocesis);

    setDiocesisName(diocesisEdit.nombre_diocesis);
    setNomenclatura(diocesisEdit.nomenclatura);
    setStatusDiocesis(diocesisEdit.borrado);
    setSelectedDiocesis(id_diocesis);

    handleClickOpen();
    setDialogTitle('Editar Diócesis');
    setDialogDescription('Ingrese los datos a actualizar de la Diócesis.');
    setIsEditMode(true);
  }

  const handleEditDiocesis = async (id_diocesis) => {
    try {

      const response = await updateDiocesis(id_diocesis, diocesisName, nomenclatura, statusDiocesis);
      console.log('Respuesta: ', response);
      handleClose();
      await fetchDiocesis();
      resetForm();
      toast.success('¡Diócesis actualizada exitosamente!');

    } catch (error) {
      toast.error('¡Error al actualizar Diócesis!');
      console.error('Error al actualizar Diócesis:', error);
    }
  };

  const resetForm = () => {
    setStatusDiocesis(true);
    setDiocesisName('');
    setNomenclatura('');
  };

  return (
    <PageWrapper>

      <PageTitle title="Diócesis"/>

      <PageContent>

        <Box
          sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              border: 1,
              borderColor: 'grey.300',
              borderRadius: 1,
              p: 2,
              backgroundColor: 'white',
              mb: 2,
              width: '100%'
          }}
        >

          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={3}>
              <BasicSelect
                label="Diócesis"
                labelId="simple-select-label"
                id="simple-select"
                value={selectedDiocesis}
                onChange={handleDiocesisChange}
                options={Array.isArray(diocesis) ? diocesis.map((diocesis) => ({
                  label: diocesis.nombre_diocesis,
                  value: diocesis.id_diocesis,
                })) : []}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button 
                variant="contained" 
                size="large" 
                style={{ 
                  height: '100%', 
                  width: '100%',
                  backgroundColor: 'green' 
                }} 
                onClick={openCreateDiocesis}
              >
                <AddCircleOutlineIcon />
              </Button>
            </Grid>
          </Grid>
          
        </Box>

        <PaginationTable
          columns={columns}
          rows={rows}
          renderActions={(row) => (
            <>
              <Button variant="outlined" onClick={() => openEditDiocesis(row.id)}>
                <EditIcon/>
              </Button>
            </>
          )}
        />
        
        {/* Sección carga/edición datos de Diócesis */}
        <Dialog 
          open={open} 
          onClose={handleClose} 
          fullScreen={fullScreen} 
          maxWidth={maxWidth} 
          PaperProps={{ style: { minWidth: '200px'} }}>
            
          <DialogTitle>{ dialogTitle }</DialogTitle>
          <DialogContent>
            <DialogContentText>
              { dialogDescription }
            </DialogContentText>
          </DialogContent>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12}>
                  <BasicSelect
                    label="Estado"
                    labelId="select-status"
                    id="select-status"
                    value={statusDiocesis}
                    onChange={(event) => setStatusDiocesis(event.target.value)}
                    options={[
                      { label: 'Activo', value: true },
                      { label: 'Inactivo', value: false },
                    ]}
                    sx={{ width: '100%', mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <TextField
                    id="nombre_diocesis"
                    label="Nombre Diócesis"
                    value={diocesisName}
                    onChange={(event) => setDiocesisName(event.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <TextField
                    id="nomenclatura"
                    label="Nomenclatura"
                    value={nomenclatura}
                    onChange={(event) => setNomenclatura(event.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

              </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleConfirmAction} disabled={!isConfirmEnabled}>Confirmar</Button>
          </DialogActions>
        </Dialog>

      </PageContent>

    </PageWrapper>
  );

}

export default Diocesis;
