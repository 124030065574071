import axios from 'axios';

const API_URL = process.env.REACT_APP_API_URL;

export const getUserInfo = async (token) => {
    try {
        const response = await axios.get(`${API_URL}/usuarios/user-info`, {
            headers: {
                'Authorization': `Bearer ${token}`
            }
        });

        return response.data;

    } catch (error) {
        console.log(error);
        return null;
    }
}

export const getAllUsersMatrimonios = async () => {

    try {
        const response = await axios.get(`${API_URL}/usuarios/matrimonios`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
    
}

export const getAllUsersJovenes = async () => {

    try {
        const response = await axios.get(`${API_URL}/usuarios/jovenes`);
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
    
}

export const assignRol = async (matrimonioId, rolId) => {

    try {
        const response = await axios.put(`${API_URL}/usuarios/assign-rol/`, {
            matrimonioId,
            rolId: rolId === 0 ? null : rolId
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
    
}

export const asignarRolJoven = async (jovenId, rolId) => {

    try {
        const response = await axios.put(`${API_URL}/usuarios/assign-rol-joven`, {
            jovenId,
            rolId: rolId === 0 ? null : rolId
        });
        return response.data;
    } catch (error) {
        console.log(error);
        return null;
    }
    
}