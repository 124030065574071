import { Tooltip } from '@mui/material';
import React from 'react';

const TestingBanner = () => {
  const isTesting = process.env.REACT_APP_ENV === 'testing';

  if (!isTesting) return null;

  return (
    <span
        style={{
            backgroundColor: 'red',
            padding: '8px',
            color: 'white',
            textAlign: 'center',
            borderRadius: '5px',
            marginLeft: '8px',
            fontSize: '1rem',
        }}
    >
      <Tooltip title="Estás conectado a un entorno de pruebas de la aplicación, esta versión contiene una copia de la base de datos de producción sin datos sensibles como números de telefono, correo, dirección, etc.">
        ⚠️ Entorno de PRUEBAS
      </Tooltip>
    </span>
  );
};

export default TestingBanner;
