import React, { useState, useEffect } from 'react';
import PageTitle from '../../components/pageContent/PageTitle';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import { Box, Table, TableBody, TableCell, TableContainer, TableHead, TableRow, Paper, Button, Dialog, DialogTitle, DialogContent, DialogActions } from '@mui/material';
import { obtenerCantidadMatrimonios } from '../../services/reportMatrimoniosService';
import SearchIcon from '@mui/icons-material/Search';
import Badge from '@mui/material/Badge';
import { makeStyles } from '@mui/styles';

const useStyles = makeStyles({
  tableRow: {
    height: 60, // Cambia 60 por la altura que desees
  },
  tableCell: {
    padding: '16px', // Ajusta el relleno según sea necesario
  }
});

function Reportes() {
  const [cantidadMatrimonios, setCantidadMatrimonios] = useState([]);
  const [selectedDiocesis, setSelectedDiocesis] = useState(null);
  const [filteredBases, setFilteredBases] = useState([]);
  const [open, setOpen] = useState(false);
  const classes = useStyles();

  useEffect(() => {
    async function fetchData() {
      try {
        const data = await obtenerCantidadMatrimonios();
        setCantidadMatrimonios(data);
      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }

    fetchData();
  }, []);

  const handleOpen = (diocesisId) => {
    setSelectedDiocesis(diocesisId);
    const filtered = cantidadMatrimonios.filter(item => item.id_diocesis === diocesisId);
    setFilteredBases(filtered);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const uniqueDiocesis = [...new Set(cantidadMatrimonios.map(item => item.nombre_diocesis))];

  // Calcular la cantidad total sumando todas las diócesis
  const totalCantidad = cantidadMatrimonios.reduce((sum, item) => sum + parseInt(item.cant), 0);

  return (
    <PageWrapper>
      <PageTitle title="Reporte cantidad de matrimonios" />
      <PageContent>
        <Box sx={{ px: 10, pb: 5 }}>
          <TableContainer component={Paper} sx={{ width: '60%', margin: '0 auto' }}>
            <Table>
              <TableHead>
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold' }}>Diócesis</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Cantidad</TableCell>
                  <TableCell sx={{ fontWeight: 'bold' }}>Bases Parroquiales</TableCell>
                </TableRow>
              </TableHead>
              <TableBody>
                {uniqueDiocesis.map((diocesis, index) => {
                  const totalCant = cantidadMatrimonios
                    .filter(item => item.nombre_diocesis === diocesis)
                    .reduce((sum, item) => sum + parseInt(item.cant), 0);
                  const diocesisId = cantidadMatrimonios.find(item => item.nombre_diocesis === diocesis).id_diocesis;

                  return (
                    <TableRow key={index} className={classes.tableRow}>
                      <TableCell className={classes.tableCell}>{diocesis}</TableCell>
                      <TableCell className={classes.tableCell}>{totalCant}</TableCell>
                      <TableCell className={classes.tableCell}>
                        <SearchIcon variant="contained" color="primary" onClick={() => handleOpen(diocesisId)} sx={{ cursor: 'pointer' }} fontSize="large">
                        </SearchIcon>
                      </TableCell>
                    </TableRow>
                  );
                })}
                <TableRow>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '1em' }}>Total matrimonios</TableCell>
                  <TableCell sx={{ fontWeight: 'bold', fontSize: '1em' }}>
                    <Badge color="primary" badgeContent={totalCantidad} max={10000} sx={{ '& .MuiBadge-badge': { fontSize: '1.2em' } }}>
                    </Badge>
                  </TableCell>
                  <TableCell />
                </TableRow>
              </TableBody>
            </Table>
          </TableContainer>

          <Dialog
            open={open}
            onClose={handleClose}
            aria-labelledby="dialog-title"
            aria-describedby="dialog-description"
          >
            <DialogTitle id="dialog-title">
              Bases Parroquiales de {selectedDiocesis && cantidadMatrimonios.find(item => item.id_diocesis === selectedDiocesis).nombre_diocesis}
            </DialogTitle>
            <DialogContent>
              <TableContainer component={Paper}>
                <Table>
                  <TableHead>
                    <TableRow>
                      <TableCell sx={{ fontWeight: 'bold' }}>Base</TableCell>
                      <TableCell sx={{ fontWeight: 'bold' }}>Cantidad</TableCell>
                    </TableRow>
                  </TableHead>
                  <TableBody>
                    {filteredBases.map((base, index) => (
                      <TableRow key={index}>
                        <TableCell>{base.nombre_base}</TableCell>
                        <TableCell>{base.cant}</TableCell>
                      </TableRow>
                    ))}
                  </TableBody>
                </Table>
              </TableContainer>
            </DialogContent>
            <DialogActions>
              <Button onClick={handleClose} variant="contained" color="primary">
                Cerrar
              </Button>
            </DialogActions>
          </Dialog>
        </Box>
      </PageContent>
    </PageWrapper>
  );
}

export default Reportes;
