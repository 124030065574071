import { format } from 'date-fns';

export const convertDate = (isoString) => {
  if (!isoString) {
    return null;
  }

  // Parsear la fecha sin tener en cuenta el desfase horario local
  const date = new Date(isoString);
  const utcDate = new Date(date.getUTCFullYear(), date.getUTCMonth(), date.getUTCDate());

  return format(utcDate, 'dd-MM-yyyy');
};
  
export const getToday = () => {
  const today = new Date();
  const year = today.getFullYear();
  const month = (today.getMonth() + 1).toString().padStart(2, '0'); // Meses son de 0 a 11
  const day = today.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`; // Formato yyyy-mm-dd
};

export const getLocalDateString = (date) => {
  const year = date.getFullYear();
  const month = (date.getMonth() + 1).toString().padStart(2, '0'); // Meses son de 0 a 11
  const day = date.getDate().toString().padStart(2, '0');

  return `${year}-${month}-${day}`; // Formato yyyy-mm-dd
};

export const convertToDateString = (dateString) => {
  const date = new Date(dateString);
  return isNaN(date.getTime()) ? '' : date.toISOString().split('T')[0];
};

export const convertToInputDateFormat = (dateString) => {
  if (!dateString) return '';
  
  // Separar la fecha en día, mes y año
  const [day, month, year] = dateString.split('-');

  // Retornar en formato `yyyy-mm-dd`
  return `${year}-${month}-${day}`;
};