import Button from "@mui/material/Button";
import CssBaseline from "@mui/material/CssBaseline";
import TextField from "@mui/material/TextField";
import Paper from "@mui/material/Paper";
import Box from "@mui/material/Box";
import Grid from "@mui/material/Grid";
import Typography from "@mui/material/Typography";
import { Container } from "@mui/material";
import { useNavigate } from "react-router-dom";
import toast from 'react-hot-toast';
import { login } from '../../services/authService';
import { getUserInfo } from "../../services/userService";
import useStore from '../../store/store';
import { useCallback } from "react";
import TestingBanner from "../../components/testingBanner/TestingBanner";


export default function Login() {

    const { setToken, setNivelAcceso, setTipoUsuario, clearSession, setUsername, setPermisos } = useStore();
    const navigate = useNavigate();

    const handleSubmit = useCallback(async (event) => {
        event.preventDefault();
        const data = new FormData(event.currentTarget);
    
        const username = data.get("username")?.trim();
        const password = data.get("password")?.trim();
    
        if (!username || !password) {
            toast.error("Por favor, ingresa usuario y contraseña.");
            return;
        }
    
        try {
            clearSession(); // Limpia la sesión antes de intentar iniciar sesión
    
            const response = await login(username, password);
    
            if (response?.status === 200 && response.data?.token) {
                const newToken = response.data.token;
                setToken(newToken); // Guarda el token en el estado
    
                // Obtener información del usuario
                const userInfo = await getUserInfo(newToken);
                setNivelAcceso(userInfo.id_nivel_acceso);
                setUsername(userInfo.usuario);
                setTipoUsuario(userInfo.tipo_usuario);
                setPermisos(userInfo.permisos);
    
                toast.success("¡Bienvenido al Sistema del MFC!");
                navigate("/home"); // Redirige al usuario a la página de inicio
            } else {
                throw new Error(response.data?.mensaje || "¡Usuario o contraseña incorrecto!");
            }
        } catch (error) {
            toast.error(error.message || "Error al iniciar sesión.");
        }
    }, []);


    return (
        <Container component="main" maxWidth="lg" sx={{ backgroundColor: 'grey.100' }}>
            <Box
                sx={{
                    minHeight: "100vh",  // Ocupar toda la altura de la pantalla
                    display: "flex",  // Activar Flexbox
                    justifyContent: "center",  // Centrar horizontalmente
                    alignItems: "center",  // Centrar verticalmente
                }}
            >
                <Grid container justifyContent="center">
                    <CssBaseline />
                    <Grid
                        item
                        xs={12}
                        sm={8}
                        md={5}
                        component={Paper}
                        elevation={6}
                        square
                        sx={{ position: "relative", overflow: "visible", padding: 1 }}
                        boxShadow={"rgba(255, 0, 0, 0.54) 0px 5px 40px"}
                    >
                        {/* Logo sobre el cuadro */}
                        <img
                            src="../img/logomfc.webp"
                            alt="Logo MFC"
                            width={100}
                            style={{
                                position: "absolute",
                                top: 0,
                                left: "50%",
                                transform: "translate(-50%, -50%)",
                                backgroundColor: "white", // Opcional, para que el logo no se mezcle con el fondo
                                borderRadius: "50%", // Si el logo es redondo, se ve mejor
                                padding: "8px", // Espaciado interno para evitar recortes
                                boxShadow: "5px 6px 6px rgba(253, 2, 2, 0.32)" // Sombra para resaltar
                            }}
                        />

                        <Box sx={{ my: 8, mx: 4, display: "flex", flexDirection: "column", alignItems: "center" }}>
                            <Typography component="h1" variant="h5">
                                Sistema MFC
                            </Typography>
                            <TestingBanner />

                            <Box component="form" noValidate onSubmit={handleSubmit} sx={{ mt: 3 }}>
                                <TextField margin="normal" required fullWidth id="username" label="Ingresar usuario" name="username" autoComplete="username" autoFocus />
                                <TextField margin="normal" required fullWidth name="password" label="Ingresar contraseña" type="password" id="password" autoComplete="current-password" />

                                <Button type="submit" fullWidth variant="contained" sx={{ mt: 3, mb: 2 }}>
                                    Ingresar
                                </Button>
                            </Box>
                        </Box>
                    </Grid>

                </Grid>
            </Box>
        </Container>
    );
}
