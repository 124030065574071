import React, { useState } from 'react';
import PropTypes from 'prop-types';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import Tabs from '@mui/material/Tabs';
import Tab from '@mui/material/Tab';
import Typography from '@mui/material/Typography';
import Box from '@mui/material/Box';
import PaginationTable from '../../components/tables/PaginationTable';
import Button from '@mui/material/Button';
import EditIcon from '@mui/icons-material/Edit';
import { getDiocesis } from '../../services/diocesisService';
import { getBases } from '../../services/baseService';
import { getGrupos, createGrupo, updateGrupo } from '../../services/grupoService';
import { getGruposJovenes, createGrupoJovenes, updateGrupoJovenes } from '../../services/grupoJovenesService';
import { useEffect } from 'react';
import Grid from '@mui/material/Grid';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import BasicSelect from '../../components/basicSelect/BasicSelect';
import { Dialog, DialogActions, DialogContent, DialogContentText, DialogTitle, TextField } from '@mui/material';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';



function TabPanel(props) {
  const { children, selectedTabIndex, index, ...otherProps } = props;


  return (
    <div
      role="tabpanel"
      hidden={selectedTabIndex !== index}
      id={`tab-panel-${index}`}
      aria-labelledby={`tab-${index}`}
      {...otherProps}
    >
      {selectedTabIndex === index && (
        <Box sx={{ p: 3 }}>
          <Typography>{children}</Typography>
        </Box>
      )}
    </div>
  );
}

TabPanel.propTypes = {
  children: PropTypes.node,
  index: PropTypes.number.isRequired,
  selectedTabIndex: PropTypes.number.isRequired,
};

function generateAallProps(index) {
  return {
    id: `tab-${index}`,
    'aria-controls': `tab-panel-${index}`,
  };
}

function Grupos() {
  const theme = useTheme();
  const [selectedTabIndex, setSelectedTabIndex] = useState(0);
  const [diocesis, setDiocesis] = React.useState([]);
  const [selectedDiocesis, setSelectedDiocesis] = useState('');
  const [bases, setBases] = useState([]);
  const [selectedBase, setSelectedBase] = useState('');
  const [grupos, setGrupos] = useState([]);
  const [selectedGrupo, setSelectedGrupo] = useState('');
  const [rows, setRows] = React.useState([]);
  const [open, setOpen] = useState(false);
  const [dialogTitle, setDialogTitle] = useState('');
  const [dialogDescription, setDialogDescription] = useState('');
  const [isEditMode, setIsEditMode] = useState(false);
  const [isConfirmEnabled, setIsConfirmEnabled] = useState(false);
  const [statusGrupo, setStatusGrupo] = useState(1);  
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [maxWidth] = React.useState('md');  
  const [selectedDiocesisForm, setSelectedDiocesisForm] = useState('');
  const [selectedBaseForm, setSelectedBaseForm] = useState('');
  const [nombreGrupoForm, setNombreGrupoForm] = useState('');
  const [estadoSelector, setEstadoSelector] = useState(1);
  const [todosGrupos, setTodosGrupos] = useState([]);


  const columns = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'nombre_grupo', label: 'Grupo', minWidth: 200 },
    { id: 'nombre_base', label: 'Base Parroquial', minWidth: 200 },
    { id: 'nombre_diocesis', label: 'Diócesis', minWidth: 200 },
    { id: 'estado', label: 'Estado', minWidth: 200 },

  ]; 
  
  const handleTabChange = (event, newTabIndex) => {
    setSelectedTabIndex(newTabIndex);
    if (newTabIndex === 0) {
      fetchGrupos();
    } else {
      fetchGruposJovenes();
    }
  };

  useEffect(() => {
    async function fetchData() {
      try {

        await fetchDiocesis();
        await fetchBases();
        if(selectedTabIndex === 0){
          await fetchGrupos();
        }else{
          await fetchGruposJovenes();
        }

      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }
  
    fetchData();
  }, []);  

  useEffect(() => {

    const newRows = grupos.map((grupo) => ({
      id: selectedTabIndex === 0 ? grupo.id_grupo : grupo.id_grupo_joven,
      nombre_grupo: grupo.nombre_grupo,
      nombre_base: grupo.bases.nombre_base,
      nombre_diocesis: grupo.bases.diocesis.nombre_diocesis,
      estado: grupo.estado === 1 ? 'Activo' : 'Inactivo'
    }));

    setRows(newRows);

  }, [grupos]);

  useEffect(() => {
    if (nombreGrupoForm && selectedBaseForm && selectedDiocesisForm) {
        setIsConfirmEnabled(true);
    } else {
        setIsConfirmEnabled(false);
    }
  }, [nombreGrupoForm, selectedBaseForm, selectedDiocesisForm]);

  const fetchGrupos = async () => {
    try {
      const response = await getGrupos();
      setGrupos(response.grupos);
      setTodosGrupos(response.grupos);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  }; 

  const fetchGruposJovenes = async () => {
    try {
      const response = await getGruposJovenes();
      setGrupos(response.grupos);
      setTodosGrupos(response.grupos);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handleGrupoChange = (event) => {
    let id_grupo = event.target.value;
    setSelectedGrupo(id_grupo);
  };  

  const fetchDiocesis = async () => {
    try {
      const response = await getDiocesis();
      setDiocesis(response.diocesis);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };  

  const handleDiocesisChange = (event) => {
    let id_diocesis = event.target.value;
    setSelectedDiocesis(id_diocesis);
  };  

  const fetchBases = async (id_diocesis = null, id_base = null) => {
    try {
      const response = await getBases(id_diocesis, id_base);
      setBases(response.bases);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };;  

  const handleBaseChange = (event) => {
    let id_base = event.target.value;
    setSelectedBase(id_base);

  };

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  }; 

  const handleConfirmAction = () => {
    if (isEditMode) {
      handleEditGrupo();
    } else {
      handleCreateGrupo();
    }
  };   

  const handleCreateGrupo = async () => {
    try {
      if(selectedTabIndex === 0){
        await createGrupo(selectedBaseForm, nombreGrupoForm, statusGrupo);
      }else{
        await createGrupoJovenes(selectedBaseForm, nombreGrupoForm, statusGrupo);
        await fetchGruposJovenes();
      }
      resetForm();
      handleClose();
      toast.success('¡Nuevo Grupo creado exitosamente!');

    }catch (error) {
      console.error('Error al obtener datos:', error);
      toast.error('¡Error al crear Grupo!');
    }
  }

  const openCreateGrupo = async () => {
    resetForm();
    handleClickOpen();
    setDialogTitle('Nuevo Grupo');
    setDialogDescription('Ingrese los datos del nuevo Grupo');
    setIsEditMode(false);
  }

  const openEditGrupo = async (id_grupo) => {
    resetForm();
    setDialogTitle('Editar Grupo');
    setDialogDescription('Ingrese los datos del Grupo');
    setIsEditMode(true);
    const grupoEdit = grupos.find(g => selectedTabIndex === 0 ? g.id_grupo === id_grupo : g.id_grupo_joven === id_grupo);
    handleClickOpen();
    setSelectedDiocesisForm(grupoEdit.bases.diocesis.id_diocesis);
    setSelectedBaseForm(grupoEdit.bases.id_base);
    setNombreGrupoForm(grupoEdit.nombre_grupo);
    setStatusGrupo(grupoEdit.estado);
    setSelectedGrupo(id_grupo);
  }

  const handleEditGrupo = async () => {
    try {
      let response = null;
      if(selectedTabIndex === 0){
        response = await updateGrupo(selectedGrupo, selectedBaseForm, nombreGrupoForm, statusGrupo);
        await fetchGrupos();
      }else{
        response = await updateGrupoJovenes(selectedGrupo, selectedBaseForm, nombreGrupoForm, statusGrupo);
        await fetchGruposJovenes();
      }
      resetForm();
      handleClose();
      toast.success('¡Grupo editado exitosamente!');
    }catch (error) {
      console.error('Error al obtener datos:', error);
      toast.error('¡Error al editar Grupo!');
    }
  }

  const resetForm = () => {
    setSelectedDiocesisForm('');
    setSelectedBaseForm('');
    setNombreGrupoForm('');
  };

  const handleGrupoStateChange = (event) => {
    let state = event.target.value;
    setEstadoSelector(state);

    let grupoFilter = [];
    if(state === 1) {
      grupoFilter = todosGrupos.filter(grupo => grupo.estado === state);
    }else{
      grupoFilter = todosGrupos.filter(grupo => grupo.estado === state);
    }
    setGrupos(grupoFilter);

  }

  return (
    <PageWrapper>
      <PageTitle title="Grupos"/>

      <PageContent>

        <Box sx={{ width: '100%', height: '100%', display: 'flex', flexDirection: 'column' }}>

          <Box sx={{ borderBottom: 1, borderColor: 'divider' }}>
            <Tabs value={selectedTabIndex} onChange={handleTabChange} aria-label="Grupos tabs">
              <Tab label="Matrimonios" {...generateAallProps(0)} />
              <Tab label="Jóvenes" {...generateAallProps(1)} />
            </Tabs>
          </Box>

          <TabPanel selectedTabIndex={selectedTabIndex} index={0}>

            {/* Sección filtros */}
            <Box
              sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 1,
                  borderColor: 'grey.300',
                  borderRadius: 1,
                  p: 2,
                  backgroundColor: 'white',
                  mb: 2,
                  width: '100%'
              }}
            >

              <Grid container spacing={2}>

                <Grid item xs={12} sm={6} md={2}>
                  <BasicSelect
                    label="Diócesis"
                    labelId="simple-select-label"
                    id="simple-select"
                    value={selectedDiocesis}
                    onChange={handleDiocesisChange}
                    options={diocesis.map((diocesis) => ({
                      label: diocesis.nombre_diocesis,
                      value: diocesis.id_diocesis,
                    }))}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <BasicSelect
                      label="Bases Parroquial"
                      labelId="simple-select-label2"
                      id="simple-select2"
                      value={selectedBase}
                      onChange={handleBaseChange}
                      options={bases.map((bases) => ({
                        label: bases.nombre_base,
                        value: bases.id_base,
                      }))}
                    />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <BasicSelect
                    label="Grupos"
                    labelId="simple-select-label"
                    id="simple-select"
                    value={selectedGrupo}
                    onChange={handleGrupoChange}
                    options={grupos.map((grupos) => ({
                      label: grupos.nombre_grupo,
                      value: grupos.id_grupo,
                    }))}
                  />
                </Grid>

                <Grid item xs={12} sm={6} md={2}>
                  <BasicSelect
                    label="Estado"
                    labelId="simple-select-label"
                    id="simple-select"
                    value={estadoSelector}
                    onChange={handleGrupoStateChange}
                    options={[
                      { value: 1, label: 'Activo' },
                      { value: 2, label: 'Inactivo'}
                    ]}
                  />
                </Grid>


                <Grid item xs={12} sm={6} md={3}>
                  <Button 
                    variant="contained" 
                    size="large" 
                    style={{ 
                      height: '100%', 
                      width: '100%',
                      backgroundColor: 'green' 
                    }} 
                    onClick={openCreateGrupo}
                  >
                    <AddCircleOutlineIcon />
                  </Button>
                </Grid>
              </Grid>
              
            </Box>

            <PaginationTable
              columns={columns}
              rows={rows}
              renderActions={(row) => (
                <>
                  <Button variant="outlined" onClick={() => openEditGrupo(row.id)}>
                    <EditIcon/>
                  </Button>
                </>
              )}
            />

          </TabPanel>

          <TabPanel selectedTabIndex={selectedTabIndex} index={1}>

            <Box
              sx={{
                  display: 'flex',
                  flexDirection: 'row',
                  justifyContent: 'center',
                  alignItems: 'center',
                  border: 1,
                  borderColor: 'grey.300',
                  borderRadius: 1,
                  p: 2,
                  backgroundColor: 'white',
                  mb: 2,
                  width: '100%'
              }}
            >

                <Grid container spacing={2}>

                  <Grid item xs={12} sm={6} md={2}>
                    <BasicSelect
                      label="Diócesis"
                      labelId="simple-select-label"
                      id="simple-select"
                      value={selectedDiocesis}
                      onChange={handleDiocesisChange}
                      options={diocesis.map((diocesis) => ({
                        label: diocesis.nombre_diocesis,
                        value: diocesis.id_diocesis,
                      }))}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <BasicSelect
                        label="Bases Parroquial"
                        labelId="simple-select-label2"
                        id="simple-select2"
                        value={selectedBase}
                        onChange={handleBaseChange}
                        options={bases.map((bases) => ({
                          label: bases.nombre_base,
                          value: bases.id_base,
                        }))}
                      />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <BasicSelect
                      label="Grupos"
                      labelId="simple-select-label"
                      id="simple-select"
                      value={selectedGrupo}
                      onChange={handleGrupoChange}
                      options={grupos.map((grupos) => ({
                        label: grupos.nombre_grupo,
                        value: grupos.id_grupo_joven,
                      }))}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={2}>
                    <BasicSelect
                      label="Estado"
                      labelId="simple-select-label"
                      id="simple-select"
                      value={estadoSelector}
                      onChange={handleGrupoStateChange}
                      options={[
                        { value: 1, label: 'Activo' },
                        { value: 2, label: 'Inactivo'}
                      ]}
                    />
                  </Grid>

                  <Grid item xs={12} sm={6} md={3}>
                    <Button 
                      variant="contained" 
                      size="large" 
                      style={{ 
                        height: '100%', 
                        width: '100%',
                        backgroundColor: 'green' 
                      }} 
                      onClick={openCreateGrupo}
                    >
                      <AddCircleOutlineIcon />
                    </Button>
                  </Grid>

              </Grid>
              
            </Box>

            <PaginationTable
              columns={columns}
              rows={rows}
              renderActions={(row) => (
                <>
                  <Button variant="outlined" onClick={() => openEditGrupo(row.id)}>
                    <EditIcon/>
                  </Button>
                </>
              )}
            />

          </TabPanel>
        </Box>

        {/* Sección carga/edición datos de Grupos */}
        <Dialog 
          open={open} 
          onClose={handleClose} 
          fullScreen={fullScreen} 
          maxWidth={maxWidth} 
          PaperProps={{ style: { minWidth: '200px'} }}>
            
          <DialogTitle>{ dialogTitle }</DialogTitle>
          <DialogContent>
            <DialogContentText>
              { dialogDescription }
            </DialogContentText>
          </DialogContent>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
            >
              <Grid container spacing={1}>

                <Grid item xs={12}>
                  <BasicSelect
                    label="Estado"
                    labelId="select-status"
                    id="select-status"
                    value={statusGrupo}
                    onChange={(event) => setStatusGrupo(event.target.value)}
                    options={[
                      { label: 'Activo', value: 1 },
                      { label: 'Inactivo', value: 2 },
                    ]}
                    sx={{ width: '100%', mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BasicSelect
                    label="Diócesis"
                    labelId="select-status"
                    id="select-status"
                    value={selectedDiocesisForm}
                    onChange={(event) => setSelectedDiocesisForm(event.target.value)}
                    options={diocesis.map((diocesis) => ({
                      label: diocesis.nombre_diocesis,
                      value: diocesis.id_diocesis,
                    }))}
                    sx={{ width: '100%', mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12}>
                  <BasicSelect
                    label="Base Parroquial"
                    labelId="select-status"
                    id="select-status"
                    value={selectedBaseForm}
                    onChange={(event) => setSelectedBaseForm(event.target.value)}
                    options={bases.map((bases) => ({
                      label: bases.nombre_base,
                      value: bases.id_base,
                    }))}
                    sx={{ width: '100%', mt: 2 }}
                  />
                </Grid>
                <Grid item xs={12} mt={2}>
                  <TextField
                    id="nombre_grupo"
                    label="Nombre Grupo"
                    value={nombreGrupoForm}
                    onChange={(event) => setNombreGrupoForm(event.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>

              </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleConfirmAction} disabled={!isConfirmEnabled}>Confirmar</Button>
          </DialogActions>
        </Dialog>        
      </PageContent>
    </PageWrapper>
  );
}

export default Grupos;
