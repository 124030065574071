import React from 'react';
import { Route, Routes, useLocation } from 'react-router-dom';
import Login from '../../views/sessions/Login';
import Home from '../../views/Home';
import NavBar from '../../components/navbar/NavBar';
import Diocesis from '../../views/diocesis/Diocesis';
import Bases from '../../views/bases/Bases';
import Grupos from '../../views/grupos/Grupos';
import Matrimonios from '../../views/matrimonios/Matrimonios';
import Jovenes from '../../views/jovenes/Jovenes';
import Aportes from '../../views/aportes/Aportes';
import Reportes from '../../views/reports/Reportes';
import Repositorio from '../../views/repositorio/Repositorio';
import UsuariosMatrimonios from '../../views/admin/UsuariosMatrimonios';
import UsuariosJovenes from '../../views/admin/UsuariosJovenes';
import Profile from '../../views/profile/Profile';
import ChangePassowrd from '../../views/sessions/ChangePassword';
import RecoverPassword from '../../views/sessions/RecoverPassword';
import MatrimonioForm from '../../views/matrimonios/MatrimonioForm';
import JovenForm from '../../views/jovenes/JovenForm';
import Cursos from '../../views/cursos/Cursos';
import AsignarCursoMatrimonio from '../../views/matrimonios/AsignarCursoMatrimonio';

export const AuthRoutes = () => {
    // Obtenemos la ubicación actual usando useLocation de react-router-dom
    const location = useLocation();

    // Verificamos si la ruta actual es la página de inicio o recuperar clave
    const isLoginPage = location.pathname === '/';
    const isRecoverPasswordPage = location.pathname === '/recuperar-clave';

  return (
    <>
      {/* Renderizamos el Navbar si no estamos en la página de inicio de sesión */}
      {!isLoginPage && !isRecoverPasswordPage && <NavBar />}

      <Routes>
          <Route path="/" element={<Login />} />
          <Route path="/home" element={ <Home />} />
          <Route path="/diocesis" element={<Diocesis />} />
          <Route path="/bases" element={<Bases />} />
          <Route path="/grupos" element={<Grupos />} />
          <Route path="/matrimonios" element={<Matrimonios />} />
          <Route path='/matrimonio-form' element={<MatrimonioForm />} />
          <Route path='/asignar-curso-matrimonio' element={<AsignarCursoMatrimonio />} />
          <Route path="/jovenes" element={<Jovenes />} />
          <Route path='/joven-form' element={<JovenForm />} />
          <Route path='/cursos' element={<Cursos />} />
          <Route path="/aportes" element={<Aportes />} />
          <Route path="/reportes" element={<Reportes />} />
          <Route path="/repositorio" element={<Repositorio />} />
          <Route path="/admin/usuarios-matrimonios" element={<UsuariosMatrimonios />} />
          <Route path="/admin/usuarios-jovenes" element={<UsuariosJovenes />} />
          <Route path="/perfil" element={<Profile />} />
          <Route path="/cambiar-clave" element={<ChangePassowrd />} />
          <Route path="/recuperar-clave" element={<RecoverPassword />} />
      </Routes>   
    </>
  );
};