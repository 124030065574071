import React from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import PaginationTable from '../../components/tables/PaginationTable';
import Box from '@mui/material/Box';
import BasicSelect from '../../components/basicSelect/BasicSelect';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';

function Aportes() {

  const columns = [
    { id: 'id', label: 'ID', minWidth: 100 },
    { id: 'nombre_curso', label: 'Aporte', minWidth: 200 },
    { id: 'mes', label: 'Mes', minWidth: 200},
    { id: 'anho', label: 'Años', minWidth: 200},
    { id: 'membresia', label: 'Nro. Membresía', minWidth: 200}
  ]; 

  const data = [
    { id: 1, nombre_curso: '10.000', mes: 'Enero', anho: '2021', membresia: '123456'},
    { id: 2, nombre_curso: '10.000', mes: 'Enero', anho: '2021', membresia: '123456'},
    { id: 3, nombre_curso: '10.000', mes: 'Enero', anho: '2021', membresia: '123456'},
    { id: 4, nombre_curso: '10.000', mes: 'Enero', anho: '2021', membresia: '123456'},
    { id: 5, nombre_curso: '10.000', mes: 'Enero', anho: '2021', membresia: '123456'},
    { id: 6, nombre_curso: '10.000', mes: 'Enero', anho: '2021', membresia: '123456'},
    { id: 7, nombre_curso: '10.000', mes: 'Enero', anho: '2021', membresia: '123456'},
    { id: 8, nombre_curso: '10.000', mes: 'Enero', anho: '2021', membresia: '123456'},
    { id: 9, nombre_curso: '10.000', mes: 'Enero', anho: '2021', membresia: '123456'},
  ];


  return (
    <PageWrapper>

      <PageTitle title="Aportes"/>

      <PageContent>

      <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 1,
            borderColor: 'grey.300',
            borderRadius: 1,
            p: 2,
            backgroundColor: 'white',
            mb: 2,
            width: '100%'
        }}
        >
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={3}>
              <BasicSelect
                label="Tipo de Aporte"
                labelId="simple-select-label"
                id="simple-select"
                value={''}
                onChange={''}
                options={[
                  { label: 'SPLA', value: 1 },
                  { label: 'Concepto 2', value: 2 },
                  { label: 'Concepto 3', value: 3 },
                ]}
              />
            </Grid>
            <Grid item xs={12} sm={4} md={2}>
              <Button 
                variant="contained" 
                size="large" 
                style={{ 
                  height: '100%', 
                  width: '100%',
                  backgroundColor: 'green' 
                }} 
              >
                <AddCircleOutlineIcon />
              </Button>
            </Grid>
          </Grid>
        </Box>  
          
        <PaginationTable
          columns={columns}
          rows={data}
        />

      </PageContent>

    </PageWrapper>
  );
}

export default Aportes;
