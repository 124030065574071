import React from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import PaginationTable from '../../components/tables/PaginationTable';
import { getJovenes, inactivateJoven, activateJoven } from '../../services/jovenesService';
import { useEffect } from 'react';
import EditIcon from '@mui/icons-material/Edit';
import { useNavigate } from 'react-router-dom';
import { useState } from 'react';
import { Box, Grid } from '@mui/material';
import BasicSelect from '../../components/basicSelect/BasicSelect';
import { getDiocesis } from '../../services/diocesisService';
import { getBases } from '../../services/baseService';
import { getGruposJovenes } from '../../services/grupoJovenesService';
import Button from '@mui/material/Button';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import ArrowDownwardIcon from '@mui/icons-material/ArrowDownward';
import ArrowUpwardIcon from '@mui/icons-material/ArrowUpward';
import Tooltip from '@mui/material/Tooltip';
import Dialog from '@mui/material/Dialog';
import DialogTitle from '@mui/material/DialogTitle';
import DialogContent from '@mui/material/DialogContent';
import DialogContentText from '@mui/material/DialogContentText';
import DialogActions from '@mui/material/DialogActions';
import TextField from '@mui/material/TextField';
import useMediaQuery from '@mui/material/useMediaQuery';
import { useTheme } from '@mui/material/styles';
import toast from 'react-hot-toast';
import { getToday } from '../../utils/utils';

function Jovenes() {
  const theme = useTheme();
  const [rows, setRows] = React.useState([]);
  const [jovenes, setJovenes] = React.useState([]);
  const [diocesis, setDiocesis] = React.useState([]);
  const [bases, setBases] = React.useState([]);
  const [selectedBase, setSelectedBase] = React.useState('');
  const [grupos, setGrupos] = React.useState([]);
  const [selectedGrupo, setSelectedGrupo] = React.useState('');
  const [selectedDiocesis, setSelectedDiocesis] = React.useState('');
  const [selectedEstado, setSelectedEstado] = React.useState(5);
  const [open, setOpen] = React.useState(false);
  const [idJovenBaja, setIdJovenBaja] = React.useState(null);
  const [motivoBaja, setMotivoBaja] = React.useState('');
  const fullScreen = useMediaQuery(theme.breakpoints.down('md'));
  const [maxWidth] = React.useState('md');
  const [openConfirmActivate, setOpenConfirmActivate] = React.useState(false);
  const [idJovenAlta, setIdJovenAlta] = useState(null);
  const [search, setSearch] = useState('');

  const navigate = useNavigate (); 

  const columns = [
    { id: 'id_joven', label: 'ID', minWidth: 50 },
    { id: 'cedula', label: 'CI ', minWidth: 50 },
    { id: 'nombre_apellido', label: 'Nombres y Apellidos', minWidth: 150 },
    { id: 'nombre_diocesis', label: 'Diócesis', minWidth: 100 },
    { id: 'nombre_base', label: 'Base Parroquial', minWidth: 100 },
    { id: 'nombre_grupo', label: 'Grupo', minWidth: 150 },
    { id: 'descripcion_estado', label: 'Estado', minWidth: 100 },
  ];

  useEffect(() => {
    async function fetchData() {
      try {
        
        await fetchJovenes();
        await fetchDiocesis();
        await fetchBases();
        await fetchGruposJovenes();

      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }

    fetchData();
  }, []);   

  useEffect(() => {

    let filteredJovenes = jovenes;

    if (selectedDiocesis) {
      // Filtrar por diócesis seleccionada
      filteredJovenes = filteredJovenes.filter((joven) => joven.id_diocesis === selectedDiocesis);
      console.log('filteredJovenes', filteredJovenes);
    }
    if (selectedBase) {
      // Filtrar adicionalmente por base seleccionada
      filteredJovenes = filteredJovenes.filter((joven) => joven.id_base === selectedBase);
    }    
    if (selectedGrupo) {
      // Filtrar adicionalmente por grupo seleccionado
      filteredJovenes = filteredJovenes.filter((joven) => joven.id_grupo_joven === selectedGrupo);
    }
    if (selectedEstado !== 5) {
      // Filtrar adicionalmente por estado seleccionado
      filteredJovenes = filteredJovenes.filter(m => m.id_estado_miembro === selectedEstado);
    }

    // Filtrar jovenes por texto de búsqueda
    if (search.trim() !== '') {
      const searchTerm = search.toLowerCase();
      filteredJovenes = filteredJovenes.filter(joven => 
        joven.cedula?.toString().includes(search) ||
        joven.nombre_apellido?.toLowerCase().includes(searchTerm) ||
        joven.nombre_diocesis?.toLowerCase().includes(searchTerm) ||
        joven.nombre_base?.toLowerCase().includes(searchTerm) ||
        joven.nombre_grupo?.toLowerCase().includes(searchTerm) ||
        joven.descripcion_estado?.toLowerCase().includes(searchTerm)
      );
    }

    const rowsData = filteredJovenes.map((joven) => ({
      id_joven: joven.id_joven,
      cedula: joven.cedula,
      nombre_apellido: joven.nombre_apellido,
      nombre_grupo: joven.nombre_grupo,
      nombre_diocesis: joven.nombre_diocesis,
      nombre_base: joven.nombre_base,
      descripcion_estado: joven.descripcion_estado,
    }));

    setRows(rowsData);
  }, [jovenes, selectedDiocesis, selectedBase, selectedGrupo, selectedEstado, search]);

  const fetchJovenes = async () => {
    try {
      const response = await getJovenes();
      setJovenes(response.jovenes);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };   

  const handleEditClick = (idJoven) => {
    navigate('/joven-form', { state: { idJoven } });
  };  

  const handleDiocesisChange = (event) => {
    let id_diocesis = event.target.value;
    setSelectedDiocesis(id_diocesis);
    fetchBases(id_diocesis);
  }; 

  const handleBaseChange = (event) => {
    let id_base = event.target.value;
    setSelectedBase(id_base);
    fetchGruposJovenes(id_base);
  };

  const handleGrupoChange = (event) => {
    let id_grupo = event.target.value;
    setSelectedGrupo(id_grupo);
  };

  const fetchDiocesis = async () => {
    try {
      const response = await getDiocesis();
      setDiocesis(response.diocesis);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  }; 

  const fetchBases = async (id_diocesis = null, id_base = null) => {
    try {
      const response = await getBases(id_diocesis, id_base);
      setBases(response.bases);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const fetchGruposJovenes = async (id_base = null, id_grupo = null) => {
    try {
      const response = await getGruposJovenes(id_base, id_grupo);
      setGrupos(response.grupos);
    } catch (error) {
      console.error('Error al obtener datos:', error);
    }
  };

  const handleEstadoChange = (event) => {
    let id_estado = event.target.value;
    setSelectedEstado(id_estado);
  };

  const openViewJovenes = () => {
    navigate('/joven-form');
  }

  const handleBajaJoven = async () => {
    if (idJovenBaja) {
      await inactivateJoven(idJovenBaja, motivoBaja);
      await fetchJovenes();
      toast.success('Joven dado de baja');
    }
    setOpen(false);
    setIdJovenBaja(null);
    setMotivoBaja('');
  }

  const handleClickOpen = (idJoven) => {
    console.log('Baja de joven con id: ', idJoven);
    setIdJovenBaja(idJoven);
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };   

  const handleClickOpenActivate = (idJoven) => {
    setIdJovenAlta(idJoven);
    setOpenConfirmActivate(true);
  }

  const handleActivate = async () => {
    if (idJovenAlta) {
      await activateJoven(idJovenAlta);
      await fetchJovenes();
      toast.success('Joven activado');
    }
    setOpenConfirmActivate(false);
    setIdJovenAlta(null);
  }

  const handleCloseActivate = () => {
    setOpenConfirmActivate(false);
  }

  return (
    <PageWrapper>

      <PageTitle title="Jóvenes"/>

      <PageContent>


        {/* Sección filtros */}
        <Box
          sx={{
              display: 'flex',
              flexDirection: 'row',
              justifyContent: 'center',
              alignItems: 'center',
              border: 1,
              borderColor: 'grey.300',
              borderRadius: 1,
              p: 2,
              backgroundColor: 'white',
              mb: 2,
              width: '100%'
          }}
          >
            <Grid container spacing={2}>

              <Grid item xs={12} sm={6} md={2}>
                <TextField
                    fullWidth
                    label="Buscar"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} 
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <BasicSelect
                  label="Diócesis"
                  labelId="simple-select-label"
                  id="simple-select"
                  value={selectedDiocesis}
                  onChange={handleDiocesisChange}
                  options={diocesis.map((diocesis) => ({
                    label: diocesis.nombre_diocesis,
                    value: diocesis.id_diocesis,
                  }))}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <BasicSelect
                    label="Bases Parroquial"
                    labelId="simple-select-label2"
                    id="simple-select2"
                    value={selectedBase}
                    onChange={handleBaseChange}
                    options={bases.map((bases) => ({
                      label: bases.nombre_base,
                      value: bases.id_base,
                    }))}
                  />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
                <BasicSelect
                  label="Grupos"
                  labelId="simple-select-label"
                  id="simple-select"
                  value={selectedGrupo}
                  onChange={handleGrupoChange}
                  options={grupos.map((grupos) => ({
                    label: grupos.nombre_grupo,
                    value: grupos.id_grupo_joven,
                  }))}
                />
              </Grid>

              <Grid item xs={12} sm={6} md={2}>
              <BasicSelect
                label="Estado"
                labelId="simple-select-label"
                id="simple-select"
                value={selectedEstado}
                onChange={handleEstadoChange}
                options={[
                  { value: 5, label: 'Todos'},
                  { value: 2, label: 'Activo' },
                  { value: 3, label: 'Inactivo'},
                  // { value: 1, label: 'Pendiente'},
                  // { value: 4, label: 'Traslado'}
                ]}
              />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
              <Button variant="contained" size="large" style={{ height: '100%', marginLeft: '10px', backgroundColor: 'green' }} onClick={ openViewJovenes } ><AddCircleOutlineIcon /></Button>
            </Grid>

          </Grid>
        </Box>

        {/* Sección tabla */}
        <PaginationTable
          columns={columns}
          rows={rows}
          renderActions={(row) => (
            <>
              { row.descripcion_estado === 'ACTIVO' && (
                <>
                  <Tooltip title="Editar" placement="top">
                    <EditIcon onClick={ () => handleEditClick(row.id_joven) }/>
                  </Tooltip>
                  <Tooltip title="Inactivar" placement="top">
                    <ArrowDownwardIcon onClick={ () => handleClickOpen(row.id_joven) } />
                  </Tooltip>
                </>
              )}
              { row.descripcion_estado === 'INACTIVO' && 
                <Tooltip title="Activar" placement="top">
                  <ArrowUpwardIcon onClick={ () => handleClickOpenActivate(row.id_joven) } />
                </Tooltip> 
              }
            </>
          )}
        />

        {/* Sección para dar de baja un joven*/}
        <Dialog 
          open={open} 
          onClose={handleClose} 
          fullScreen={fullScreen} 
          maxWidth={maxWidth} 
          PaperProps={{ style: { minWidth: '500px'} }}>
            
          <DialogTitle>Baja de Joven</DialogTitle>
          <DialogContent>
            <DialogContentText>
              Seleccionar datos para la baja del joven
            </DialogContentText>
          </DialogContent>
          <DialogContent
            sx={{
              display: 'flex',
              flexDirection: 'column',
              width: '100%',
            }}
            >
              <Grid container spacing={1}>
                <Grid item xs={12} mt={2}>
                  <TextField
                    id="fecha-baja"
                    label="Fecha baja"
                    type="date"
                    defaultValue={getToday()}
                    onChange={(event) => {console.log(event.target.value)}}
                    InputLabelProps={{
                      shrink: true,
                    }}
                    fullWidth
                  />
                  </Grid>
                <Grid item xs={12} mt={2}>
                  <TextField
                    id="motivo"
                    label="Motivo"
                    value={motivoBaja}
                    onChange={(event) => setMotivoBaja(event.target.value)}
                    variant="outlined"
                    fullWidth
                  />
                </Grid>
              </Grid>

          </DialogContent>
          <DialogActions>
            <Button onClick={handleClose}>Cancelar</Button>
            <Button onClick={handleBajaJoven}>Confirmar</Button>
          </DialogActions>
        </Dialog>


        {/* Confirmación activación de joven */}
        <Dialog
          open={openConfirmActivate}
          onClose={handleCloseActivate}
          aria-labelledby="alert-dialog-title"
          aria-describedby="alert-dialog-description"
        >
          <DialogTitle id="alert-dialog-title">
            {"¿Desea activar el joven?"}
          </DialogTitle>
          <DialogContent>
          </DialogContent>
          <DialogActions>
            <Button onClick={handleCloseActivate}>Cancelar</Button>
            <Button onClick={handleActivate} autoFocus>
              Confirmar
            </Button>
          </DialogActions>
        </Dialog>

      </PageContent>

    </PageWrapper>
  );
}

export default Jovenes;
