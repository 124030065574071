import React from 'react';
import PageContent from '../../components/pageContent/PageContent';
import PageWrapper from '../../components/pageContent/PageWrapper';
import PageTitle from '../../components/pageContent/PageTitle';
import PaginationTable from '../../components/tables/PaginationTable';
import { useEffect, useState } from 'react';
import Box from '@mui/material/Box';
import Grid from '@mui/material/Grid';
import Button from '@mui/material/Button';
import { getCursosRealizados } from '../../services/cursoService';
import { convertDate } from '../../utils/utils';
import { TextField } from '@mui/material';
import { descargarExcelCursos } from '../../services/cursoService';
import toast from 'react-hot-toast';
import ArrowCircleDownIcon from '@mui/icons-material/ArrowCircleDown';
import Tooltip from '@mui/material/Tooltip';


function Cursos() {
  const [rows, setRows] = useState([]);
  const [filteredRows, setFilteredRows] = useState([]); 
  const [search, setSearch] = useState('');

  const columns = [
    { id: 'id', label: 'ID', minWidth: 10 },
    { id: 'fecha', label: 'Fecha', minWidth: 20 },
    { id: 'nombre_curso', label: 'Curso', minWidth: 20 },
    { id: 'nivel', label: 'Nivel', minWidth: 20},
    { id: 'matrimonio', label: 'Matrimonio', minWidth: 20 },
    { id: 'membresia', label: 'Membresía', minWidth: 20 },
    { id: 'diocesis', label: 'Diocesis', minWidth: 20 },
    { id: 'base', label: 'Base', minWidth: 20 },
  ]; 

  useEffect(() => {
    async function fetchData() {
      try {

        await fetchCursosRealizados();

      } catch (error) {
        console.error('Error al obtener datos:', error);
      }
    }
  
    fetchData();
    
  }, []);

  useEffect(() => {
    // Función para filtrar las filas basada en la búsqueda
    const filterRows = () => {
        const lowercasedSearch = search.toLowerCase();
        const filtered = rows.filter(row =>
          (
            row.nombre_curso?.toLowerCase().includes(lowercasedSearch) || 
            row.nivel?.toLowerCase().includes(lowercasedSearch) || 
            row.matrimonio?.toLowerCase().includes(lowercasedSearch) ||
            row.membresia?.toString().includes(lowercasedSearch) ||
            row.diocesis?.toLowerCase().includes(lowercasedSearch) ||
            row.base?.toLowerCase().includes(lowercasedSearch) ||
            row.fecha?.includes(lowercasedSearch)
          ) 
        );
        setFilteredRows(filtered);
      };

    if (search === '') {
        setFilteredRows(rows);
    } else {
        filterRows();
    }

  }, [search, rows]);  

  const fetchCursosRealizados = async () => {
    const response = await getCursosRealizados();

    const mappedRows = response.cursos.map(curso => ({
      id: curso.id_curso,
      nombre_curso: curso.cursos?.nombre_curso,
      fecha: curso.fecha_desde ? convertDate(curso.fecha_desde) : '',
      nivel: curso.cursos?.nivel_ciclo?.ciclo,
      matrimonio: `${curso.matrimonios?.ella_nombres} ${curso.matrimonios?.ella_apellidos} y ${curso.matrimonios?.el_nombres} ${curso.matrimonios?.el_apellidos}`,
      membresia: curso.matrimonios?.el_cedula,
      diocesis: curso.matrimonios?.grupos?.bases?.diocesis?.nombre_diocesis,
      base: curso.matrimonios?.grupos?.bases?.nombre_base
    }))

    setRows(mappedRows);
    setFilteredRows(mappedRows);
  }

  const handleDownloadExcel = async () => {
    try {
        const response = await descargarExcelCursos();
        const url = window.URL.createObjectURL(new Blob([response]));
        const link = document.createElement('a');
        document.body.appendChild(link);
        link.href = url;
        link.download = 'cursos-matrimonio.xlsx';
        link.click();
        link.remove();
    } catch (error) {
        console.error('Error al descargar el archivo:', error);
        toast.error('Error al descargar el archivo.');
    }
};
  
  return (
    <PageWrapper>

      <PageTitle title="Cursos realizados por Matrimonios"/>

      <PageContent>

      <Box
        sx={{
            display: 'flex',
            flexDirection: 'row',
            justifyContent: 'center',
            alignItems: 'center',
            border: 1,
            borderColor: 'grey.300',
            borderRadius: 1,
            p: 2,
            backgroundColor: 'white',
            mb: 2,
            width: '100%'
        }}
        >
          <Grid container spacing={2}>

            <Grid item xs={12} sm={6} md={3}>
              <TextField
                    fullWidth
                    label="Buscar"
                    variant="outlined"
                    value={search}
                    onChange={(e) => setSearch(e.target.value)} 
                />
            </Grid>

            <Grid item xs={12} sm={6} md={2}>
                <Tooltip title="Descargar planilla de cursos realizados" placement="top">
                  <Button 
                      variant="contained" 
                      size="large" 
                      style={{ height: '100%', marginLeft: '10px', backgroundColor: 'blue' }} 
                      onClick={ handleDownloadExcel } >
                      <ArrowCircleDownIcon />
                  </Button>
                </Tooltip>
            </Grid>

          </Grid>
        </Box>  
          
        <PaginationTable
          columns={columns}
          rows={filteredRows}
        />

      </PageContent>

    </PageWrapper>
  );
}

export default Cursos;
